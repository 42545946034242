import React, { useRef } from "react";
import type { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import "./email_verification.scss";
import { Label } from "../typography";

const CODE_LENGTH = 8;

const EmailVerification = ({
  setFormDisabled,
  setSecurityCode,
  email,
  error,
}: {
  setFormDisabled: Dispatch<SetStateAction<boolean>>;
  setSecurityCode: Dispatch<SetStateAction<string>>;
  email: string;
  error: string;
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const inputRefs = Array.from(new Array(CODE_LENGTH), () => useRef<HTMLInputElement | null>(null));

  const { t } = useTranslation("job_post");

  const containerClasses = classNames({
    "email-verification": true,
    "email-verification--error": !!error,
  });

  const labelClasses = classNames({
    label: true,
    "label--error": !!error,
  });

  const generateInputs = () => {
    const inputs = [];

    for (let i = 0; i < CODE_LENGTH; i++) {
      inputs.push(
        <input
          key={i}
          id={`security-input-${i}`}
          type="text"
          ref={inputRefs[i]}
          aria-invalid={!!error}
          aria-errormessage="email-verification-error"
          aria-required={true}
          maxLength={1}
          value={inputRefs[i].current?.value}
          onChange={(e) => handleChange(e, i)}
          onPaste={(e) => handlePaste(e, i)}
        />
      );
    }

    return inputs;
  };

  const handleFormDisable = (code: string) => {
    // Disable submit button until full code is entered
    if (code.length === CODE_LENGTH) {
      setFormDisabled(false);
    } else {
      setFormDisabled(true);
    }
  };

  const handleChange = (e: React.SyntheticEvent, index: number) => {
    const input = e.currentTarget as HTMLInputElement;
    const value = input.value;
    const nextInput = inputRefs[index + 1];
    const newSecurityCode = inputRefs.map((ref) => ref.current?.value);

    (inputRefs[index].current as HTMLInputElement).value = value;
    newSecurityCode[index] = value;

    const currentCode = newSecurityCode.join("");

    setSecurityCode(currentCode);
    handleFormDisable(currentCode);

    // Jump to next input if current value is not blank
    if (inputRefs[index].current?.value) {
      nextInput?.current?.select();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>, startIndex: number) => {
    const clipboardData = e.clipboardData.getData("text");
    const data = clipboardData.split("");
    let i = 0;

    inputRefs.forEach((ref, index) => {
      if (index < startIndex) {
        return;
      }

      (ref.current as HTMLInputElement).value = data[i];
      i += 1;
    });

    const currentCode = inputRefs.map((ref) => ref.current?.value).join("");

    setSecurityCode(currentCode);
    handleFormDisable(currentCode);
  };

  return (
    <div className={containerClasses}>
      <div className="divider" role="separator"></div>
      <fieldset id="email-verification">
        <legend>{t("email_verification.description", { email })}</legend>
        <Label
          aria-hidden
          className={labelClasses}
          id="email-verification-label"
          htmlFor="security-input-0"
        >
          {t("email_verification.label")}
        </Label>

        <div className="email-verification__wrapper">{generateInputs()}</div>
      </fieldset>

      {error && (
        <p
          id="email-verification-error"
          className="helper-text helper-text--error"
          aria-live="polite"
        >
          {error}
        </p>
      )}
    </div>
  );
};

export default EmailVerification;
