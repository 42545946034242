import { useState } from "react";
import { loadAppcast } from "~/utils/tracking/load_appcast";

export enum AppcastEvent {
  JobView,
  ApplyComplete,
}

const useAppcast = (
  appcastEvent: AppcastEvent,
  appcastTrackingCode?: string | null,
  internal = false
) => {
  const [appcastLoaded, setAppcastLoaded] = useState(false);

  if (!appcastLoaded && !internal) {
    void loadAppcast(!!appcastTrackingCode, appcastEvent, appcastTrackingCode);
    setAppcastLoaded(true);
  }
};

export default useAppcast;
