import { SectionHeader } from "~/components/typography";
import "./featured_posts.scss";
import { useTranslation } from "react-i18next";
import type { FeaturedBoardJobPost } from "~/types/jben/board";
import FeaturedPost from "~/components/featured_post";

interface Props {
  featuredPosts?: Array<FeaturedBoardJobPost>;
}

const FeaturedPosts = ({ featuredPosts = [] }: Props) => {
  const { t } = useTranslation("board");
  if (!featuredPosts || !featuredPosts.length) {
    return null;
  }

  return (
    <div className={"featured-posts"}>
      <SectionHeader>{t("featured_jobs")}</SectionHeader>
      <div className={"featured-posts__row"}>
        {featuredPosts.map((post) => (
          <FeaturedPost key={post.id} featuredPost={post} />
        ))}
      </div>
    </div>
  );
};

export default FeaturedPosts;
