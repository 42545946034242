import { type DemographicAnswer } from "~/utils/build_application";
import { Select, type Option } from "~/components/select";
import { useState } from "react";
import { TextInput } from "../text_input";
import { useTranslation } from "react-i18next";
import { useBoardConfiguration } from "~/hooks/use_board_configuration";

export interface DemographicFieldOption extends Option {
  freeform?: boolean;
}
export type DemographicFieldOptions = DemographicFieldOption[];

export interface DemographicFieldProps {
  label: string;
  name: string;
  required: boolean;
  type: "multi_value_single_select" | "multi_value_multi_select";
  options: DemographicFieldOptions;
  error?: string | null;
  onChange: (value: DemographicAnswer[]) => void;
}

export const DemographicField = ({
  label,
  name,
  required,
  type,
  options,
  error,
  onChange,
}: DemographicFieldProps) => {
  const { t } = useTranslation("job_post");

  const [selectedAnswers, setSelectedAnswers] = useState<DemographicAnswer[]>([]);
  const [showFreeform, setShowFreeform] = useState(false);
  const [freeformValue, setFreeformValue] = useState("");

  const { outside_label } = useBoardConfiguration();

  const freeformId = options.find((option) => option.freeform)?.value;

  const handleArrayChange = (selectedOptions: Readonly<DemographicFieldOptions>) => {
    const newValue: DemographicAnswer[] = selectedOptions.map((selectedOption) => {
      if (selectedOption.freeform) {
        return {
          id: selectedOption.value.toString(),
          freeform: freeformValue,
        };
      }

      return {
        id: selectedOption.value.toString(),
      };
    });

    const shouldShowFreeform = !!selectedOptions.find((selectedOption) => selectedOption.freeform);
    if (shouldShowFreeform) {
      setShowFreeform(true);
    } else {
      setShowFreeform(false);
      setFreeformValue("");
    }

    setSelectedAnswers(newValue);
    onChange(newValue);
  };

  const handleChange = (
    selected: DemographicFieldOption | Readonly<DemographicFieldOptions> | null
  ) => {
    if (!selected) {
      return handleArrayChange([]);
    }

    if (Array.isArray(selected)) {
      return handleArrayChange(selected);
    }

    handleArrayChange([selected] as Readonly<DemographicFieldOptions>);
  };

  const handleFreeformChange = (value: string) => {
    setFreeformValue(value);

    onChange(
      selectedAnswers.map((selectedOption) => {
        if (selectedOption.id === freeformId) {
          return {
            id: freeformId,
            freeform: value,
          };
        }

        return selectedOption;
      })
    );
  };

  const renderDropdown = () => {
    switch (type) {
      case "multi_value_multi_select":
      case "multi_value_single_select":
        return (
          <Select
            id={name}
            label={label}
            required={required}
            options={options}
            onSelect={handleChange}
            error={error}
            isMulti={type === "multi_value_multi_select"}
            isClearable
            outsideLabel={outside_label}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {renderDropdown()}
      {showFreeform && (
        <TextInput
          id={`${name}-freeform`}
          label={t("demographics.self_describe_label")}
          maxLength={255}
          onChange={(event) => handleFreeformChange(event.currentTarget.value)}
          autoFocus
          outsideLabel={outside_label}
        />
      )}
    </>
  );
};
