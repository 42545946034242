import { useState } from "react";
import Recaptcha from "~/utils/captcha/recaptcha";
import { isClient } from "~/utils/env_check";

const useRecaptcha = (internal: boolean, embedded: boolean, disableCaptcha: boolean) => {
  const [recaptchaClient, setRecaptchaClient] = useState<Recaptcha | undefined>();

  if (internal && !embedded) return;
  if (disableCaptcha) return;

  if (isClient() && !recaptchaClient) {
    const recaptcha = new Recaptcha();
    recaptcha.init();

    setRecaptchaClient(recaptcha);
  }

  return recaptchaClient;
};

export default useRecaptcha;
