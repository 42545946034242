import { scrollToElement } from "~/utils/scroll_to_element";

const focusForm = (form: HTMLFormElement | null) => {
  if (!form) {
    return;
  }

  scrollToElement(form);

  let firstInput = form.querySelector("input");
  if (firstInput) {
    firstInput.focus();
  }
};

export { focusForm };
