import type { Dispatch, SetStateAction } from "react";

import type { Field, FormFields } from "./upload_manager";
import { withNamespace } from "~/utils/translation";

type Params = {
  field: Field;
  formFields: FormFields;
  url: string;
  key: string;
};

export default class S3Uploader {
  field: Field;
  formFields: FormFields;
  url: string;
  key: string;

  constructor({ field, formFields, url, key }: Params) {
    this.field = field;
    this.formFields = formFields;
    this.url = url;
    this.key = key;
  }

  fileUrl() {
    return `${this.url}/${this.key}`;
  }

  async uploadFile(file: File, setPercentage: Dispatch<SetStateAction<number>>) {
    const MAX_FILE_SIZE = 100;
    const t = withNamespace("job_post", { prefix: "file_upload" });

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      // https://javascript.info/xmlhttprequest#upload-progress
      xhr.upload.onprogress = function (event) {
        const percentage = (event.loaded / event.total) * 100;
        setPercentage(percentage);
      };

      xhr.onloadend = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr);
        } else {
          console.error(xhr.responseText);
          console.error(`error ${this.status}`);

          const parser = new DOMParser().parseFromString(xhr.responseText, "text/xml");
          const errorCode = parser.documentElement.getElementsByTagName("Code")[0]?.textContent;
          if (errorCode === "EntityTooLarge") {
            reject(new Error(t("invalid_file_size", { file_size: MAX_FILE_SIZE }) || ""));
          }
        }
      };

      xhr.onerror = function () {
        console.error("Request failed");
      };

      xhr.open("POST", this.url);
      xhr.send(this.createForm(file));
    });
  }

  createForm(file: File) {
    const form = new FormData();

    form.append("utf8", "✓");

    for (const field in this.formFields) {
      form.append(field, this.formFields[field as keyof FormFields]);
    }

    this.key = this.key
      .replace("{timestamp}", String(Date.now()))
      .replace("{unique_id}", Math.random().toString(36).slice(2, 16));

    form.append("key", this.key);
    form.append("authenticity_token", "1234");
    form.append("Content-Type", "application/octet-stream");

    form.append("file", file);

    return form;
  }
}
