import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CheckIcon from "~/components/icons/check";
import { Resizer } from "~/utils/resizer";

interface Props {
  badgeKeys: string[];
}

const Badge = ({ badgeKey }: { badgeKey: string }) => {
  const { t } = useTranslation("job_trust_profiles");

  return (
    <div className="badge">
      <div>
        <CheckIcon size="md" />
      </div>
      <div className="badge-content">
        <div className="title">{t(`badges.${badgeKey}.title`)}</div>
        <div className="description">{t(`badges.${badgeKey}.description`)}</div>
      </div>
    </div>
  );
};

export const Content = ({ badgeKeys }: Props) => {
  const { t } = useTranslation("job_trust_profiles");

  useEffect(() => {
    // This is stupid, but sometimes there's a race condition and this causes some weirdness with the recaptcha badge.
    setTimeout(() => Resizer.getInstance().handleResize(), 100);
  }, [badgeKeys]);

  return (
    <div className="panel">
      <div className="header">
        <img src="/greenhouse_verified.svg" alt="Greenhouse Verified" />
        <div>
          <div className="title">{t(`title`)}</div>
          <div className="description wide">{t(`description`)}</div>
        </div>
      </div>
      <div className="description narrow">{t(`description`)}</div>

      <div className="badges">
        {badgeKeys.map((badgeKey) => (
          <Badge key={badgeKey} badgeKey={badgeKey} />
        ))}
      </div>
      <a
        className="learn-more-link"
        href="https://www.greenhouse.com/greenhouse-verified"
        target="_blank"
        rel="noreferrer"
      >
        {t(`learn_more`)}
      </a>
    </div>
  );
};
