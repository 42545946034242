import { useContext } from "react";
import { UrlTokenContext } from "~/contexts/url_token_context";

export const useUrlToken = () => {
  const urlToken = useContext(UrlTokenContext);

  if (urlToken === null) {
    throw new Error("useUrlToken called in a context where a url token hasn't been provided");
  }

  return urlToken;
};
