import { useTranslation } from "react-i18next";
import "./application_tracking_widget.scss";
import { useEffect, useState } from "react";
import { useBoardConfiguration } from "~/hooks/use_board_configuration";
import { Button } from "./button";
import { Body } from "./typography";

const ApplicationTrackingWidget = () => {
  const { t } = useTranslation("job_post");
  const [jobSeekersUrl, setJobSeekersUrl] = useState("");
  const { preview_mode, button_shape } = useBoardConfiguration();

  useEffect(() => {
    setJobSeekersUrl(`${window.ENV.JOB_SEEKERS_URL}/users/sign_in`);
  }, []);

  return (
    <div className="application_tracking_widget">
      <div className="application_tracking_widget__icon">
        <img src="/greenhouse_white_g.svg" alt="MyGreenhosue Application Tracking" />
      </div>
      <div className="application_tracking_widget__content">
        <div className="application_tracking_widget__content__header">
          <Body medium>
            <div>{t("application_tracking_widget.header")}</div>
          </Body>
          <Body>{t("application_tracking_widget.body")}</Body>
        </div>
        <Button
          secondary
          link
          href={preview_mode ? undefined : jobSeekersUrl}
          target="_blank"
          shape={button_shape}
        >
          {t("application_tracking_widget.create_account")}
        </Button>
      </div>
    </div>
  );
};

export default ApplicationTrackingWidget;
