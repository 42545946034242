import type { Question } from "~/types/jben/job_post";
import type { FieldValue } from "~/components/job_posts/field";
import { Field } from "~/components/job_posts/field";
import { useTranslation } from "react-i18next";
import type { OnSelectParam } from "~/components/select";

interface Props {
  question: Question;
  error?: string;
  onChange: (key: string, value: FieldValue) => void;
  excludeOption?: string;
  selected?: OnSelectParam;
}

const EeocQuestion = ({ selected, question, error, onChange, excludeOption }: Props) => {
  const field = question.fields[0];
  const { t } = useTranslation("job_post");

  return (
    <Field
      label={t(`eeoc.questions.${field.name}.label`, { defaultValue: question.label })}
      name={field.name}
      required={question.required}
      type={field.type}
      options={
        excludeOption
          ? field.values.filter((option) => option.value !== excludeOption)
          : field.values
      }
      onChange={(value) => onChange(field.name, value)}
      error={error}
      selected={selected}
    />
  );
};

export default EeocQuestion;
