import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import { useChangeLanguage } from "remix-i18next";
import { useTranslation } from "react-i18next";
import { useEffect, useContext, useRef, type CSSProperties } from "react";
import { json } from "@remix-run/node";
import type { LoaderFunctionArgs } from "@remix-run/node";
import RecruitingLogo from "./components/recruiting_logo";
import { Footer } from "./components/footer";

import "./styles/global.scss";
import "./components/typography.scss";
import "./components/footer.scss";
import { Body, SectionHeader } from "./components/typography";
import {
  isEmbeddedRoute,
  isInternalRoute,
  isJobPostRoute,
  isNewPreviewRoute,
  isPreviewRoute,
} from "~/utils/route_helpers";
import config from "~/config.server";
import { fetchBoardConfiguration } from "~/utils/fetch_board_configuration";
import { BoardConfigurationContext } from "~/contexts/board_configuration_context";
import { ErrorFlash } from "~/components/flash";
import { COLOR } from "./types/ui";
import { configuredStyles } from "~/utils/configured_styles";
import { UrlTokenContext } from "~/contexts/url_token_context";
import assetUrl from "~/utils/asset_url.server";
import { buildGoogleFontsLinks } from "./utils/google_font_helper";
import { Banner } from "./components/banner";
import "./components/banner.scss";
import { detectLocale } from "./utils/detect_locale";
import { Resizer } from "~/utils/resizer";
import classNames from "classnames";
import { getSession } from "~/utils/sessions";
import type { JbenBoardConfiguration } from "~/types/jben/jben_board_configuration";
import type { BoardConfiguration } from "~/types/board_configuration";
import { withEmotionCache } from "@emotion/react";
import ClientStyleContext from "~/contexts/client_style_context";
import { createPreviewStore } from "~/utils/preview_store";
import { usePreviewableBoardConfiguration } from "~/contexts/use_previewable_board_configuration";

export async function loader({ params, request }: LoaderFunctionArgs) {
  const locale = await detectLocale(request);
  const internal = isInternalRoute(request.url);
  const preview = isPreviewRoute(request.url);
  const newPreview = isNewPreviewRoute(request.url);
  const embedded = isEmbeddedRoute(request.url);
  const isJobPost = isJobPostRoute(request.url);
  const { searchParams, href } = new URL(request.url);
  const urlToken =
    (internal ? searchParams.get("token") : params.url_token) || searchParams.get("for") || null;

  let boardConfiguration: BoardConfiguration | null = null;
  if (newPreview) {
    const { preview_id } = params;
    const preview_data = await createPreviewStore(preview_id).getPreview();

    if (!preview_data) {
      throw new Response(null, { status: 404 });
    }
    boardConfiguration = { preview_mode: true, ...preview_data.configuration };
  } else if (preview) {
    const session = await getSession(request.headers.get("Cookie"));
    if (session.has("preview-board")) {
      boardConfiguration = session.get("preview-board").configuration as JbenBoardConfiguration;
    } else if (session.has("preview-job")) {
      boardConfiguration = session.get("preview-job").configuration as JbenBoardConfiguration;
    }
  } else if (typeof urlToken === "string") {
    const { configuration, status } = await fetchBoardConfiguration({ urlToken });

    if (status === 404 || configuration === undefined) {
      throw new Response(null, { status: 404 });
    }

    boardConfiguration = configuration;
  }

  const locationControlProvider = config.get("location_control.provider");

  return json({
    internal,
    preview,
    newPreview,
    locale,
    href,
    ENV: {
      ASSET_URL: assetUrl,
      EMAIL_ADDRESS_VALIDATOR_HOST: config.get("email_address_validator_host"),
      JBEN_URL: config.get("domains.jben_url"),
      LOCATION_CONTROL_PROVIDER: locationControlProvider,
      LOCATION_CONTROL_API_KEY:
        locationControlProvider === "pelias"
          ? config.get("location_control.pelias_api_key")
          : config.get("location_control.mapbox_api_key"),
      LOCATION_CONTROL_BASE_URL: config.get("location_control.base_url"),
      LOTUS_GIT_COMMIT: config.get("lotus.git_commit"),
      MAX_POSTS_PER_PAGE: config.get("max_posts_per_page"),
      ROLLBAR_ENV: config.get("rollbar.env"),
      ROLLBAR_FE_ENABLED: config.get("rollbar.fe_enabled"),
      ROLLBAR_POST_CLIENT_ITEM_TOKEN: config.get("rollbar.post_client_item_token"),
      ROLLBAR_FILTER_HYDRATION_ERRORS: config.get("rollbar.filter_hydration_errors"),
      GOOGLE_PICKER_APP_ID: config.get("google.picker.app_id"),
      GOOGLE_PICKER_DEVELOPER_KEY: config.get("google.picker.developer_key"),
      GOOGLE_PICKER_CLIENT_ID: config.get("google.picker.client_id"),
      DROPBOX_CHOOSER_API_KEY: config.get("dropbox.chooser_api_key"),
      GOOGLE_RECAPTCHA_INVISIBLE_KEY: config.get("google.recaptcha.invisible_key"),
      GOOGLE_RECAPTCHA_ENDPOINT: config.get("google.recaptcha.endpoint"),
      JOB_SEEKERS_URL: config.get("job_seekers.url"),
      SNOWPLOW_APP_ID: config.get("snowplow.appId"),
      SNOWPLOW_ENABLED: config.get("snowplow.enabled"),
      SNOWPLOW_ENDPOINT: config.get("snowplow.endpoint"),
      SNOWPLOW_NAMESPACE: config.get("snowplow.namespace"),
    },
    boardConfiguration,
    urlToken,
    embedded,
    isJobPost,
  });
}

const EmotionCache = withEmotionCache(
  (
    {
      children,
    }: {
      children: React.ReactNode;
    },
    emotionCache
  ) => {
    const clientStyleData = useContext(ClientStyleContext);
    const reinjectStylesRef = useRef(true);

    // Only executed on client
    // When a top level ErrorBoundary or CatchBoundary are rendered,
    // the document head gets removed, so we have to create the style tags
    useEffect(() => {
      if (!reinjectStylesRef.current) {
        return;
      }
      // re-link sheet container
      emotionCache.sheet.container = document.head;

      // re-inject tags
      const tags = emotionCache.sheet.tags;
      emotionCache.sheet.flush();
      tags.forEach((tag) => {
        (emotionCache.sheet as any)._insertTag(tag);
      });

      // reset cache to re-apply global styles
      clientStyleData.reset();
      // ensure we only do this once per mount
      reinjectStylesRef.current = false;
    }, [clientStyleData, emotionCache.sheet]);

    return children;
  }
);

export function ErrorBoundary() {
  const { t } = useTranslation("common");
  const error = useRouteError() as Error;

  const ErrorBody = () => {
    if (isRouteErrorResponse(error) && error.status === 404) {
      const message = t("errors.job_board_inactive");
      return (
        <>
          <SectionHeader>{t("errors.page_not_found")}</SectionHeader>
          <div className="job-board-inactive">
            <Body>{message}</Body>
          </div>
          <ErrorFlash message={message} />
        </>
      );
    }

    return <div>{t("errors.generic")}</div>;
  };

  return (
    <html lang="en">
      <head>
        <title>{t("errors.page_not_found")}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <div id="react-portal-mount-point" />
        <main className="main">
          <RecruitingLogo />
          <div className={"error-message font-secondary"}>
            <ErrorBody />
          </div>
        </main>
      </body>
    </html>
  );
}

export default function App() {
  let {
    internal,
    preview,
    newPreview,
    locale,
    href,
    ENV,
    boardConfiguration: initialBoardConfiguration,
    urlToken,
    embedded,
    isJobPost,
  } = useLoaderData<typeof loader>();

  const boardConfiguration = usePreviewableBoardConfiguration(initialBoardConfiguration);

  let { i18n } = useTranslation();

  useChangeLanguage(locale);

  const envScript = (
    <script
      dangerouslySetInnerHTML={{
        __html: `window.ENV = ${JSON.stringify(ENV)}`,
      }}
    />
  );

  const styles = configuredStyles(
    boardConfiguration,
    internal ? COLOR.green : COLOR.blue,
    COLOR.primaryTypography
  );

  const bodyStyles: CSSProperties = {};

  const combinedStyles = {
    ...styles,
    ...bodyStyles,
  };

  if (!embedded) {
    bodyStyles.minHeight = "100vh";
  }

  const googleFontsLinks = buildGoogleFontsLinks(boardConfiguration, newPreview);

  useEffect(() => {
    if (embedded || newPreview) {
      Resizer.getInstance().initListeners();
      Resizer.getInstance().handleResize();
    }
  }, [embedded, newPreview]);

  const mainClasses = classNames({
    main: true,
    "font-secondary": true,
    "job-post": isJobPost,
  });

  if (internal || preview) {
    const globalStyles = `
      :root {
        --custom-background-color: ${styles["--custom-background-color"]};
      }
    `;

    return (
      <EmotionCache>
        <Links />
        <style>{globalStyles}</style>
        {googleFontsLinks.map((link, i) => link && <link rel="stylesheet" href={link} key={i} />)}
        <UrlTokenContext.Provider value={urlToken}>
          <BoardConfigurationContext.Provider value={boardConfiguration}>
            <Banner url={boardConfiguration?.banner_url} internal={internal} />
            <div id="react-portal-mount-point" />
            <main className={mainClasses} style={styles}>
              <Outlet />
              {envScript}
              <Scripts />
            </main>
          </BoardConfigurationContext.Provider>
        </UrlTokenContext.Provider>
      </EmotionCache>
    );
  }

  return (
    <EmotionCache>
      <UrlTokenContext.Provider value={urlToken}>
        <BoardConfigurationContext.Provider value={boardConfiguration}>
          <html lang={locale} dir={i18n.dir()}>
            <head>
              <meta charSet="utf-8" />
              <meta name="viewport" content="width=device-width,initial-scale=1" />
              <meta property="og:image" content={boardConfiguration?.logo?.url || undefined} />
              <meta property="og:type" content="article" />
              <link rel="canonical" href={href} />
              {googleFontsLinks.map(
                (link, i) => link && <link rel="stylesheet" href={link} key={i} />
              )}
              <Meta />
              <Links />
            </head>
            <body style={combinedStyles}>
              {!embedded && <Banner url={boardConfiguration?.banner_url} internal={internal} />}
              <div id="react-portal-mount-point" />
              <main className={mainClasses}>
                <Outlet />
              </main>
              {!embedded && <Footer />}
              <ScrollRestoration />
              {envScript}
              <Scripts />
            </body>
          </html>
        </BoardConfigurationContext.Provider>
      </UrlTokenContext.Provider>
    </EmotionCache>
  );
}
