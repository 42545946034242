import { type FieldValue } from "~/components/job_posts/field";
import { type Question } from "~/types/jben/job_post";
import { invalidProfile, WEBSITE_FIELD_LABELS } from "../util";
import type { Resume, QuickApplyProfile, TrackingMetadata } from "../types";
import { type EducationAutofiller } from "./education_autofiller";
import { type EmploymentAutofiller } from "./employment_autofiller";
import type { Dispatch, SetStateAction } from "react";
import rollbar from "~/utils/rollbar.client";
import { type SnowplowTracker } from "~/utils/snowplow/snowplow_tracker";

export class Autofiller {
  private answers: Record<string, FieldValue | null>;
  private setAnswers: (answers: Record<string, FieldValue>) => void;
  private questions: Question[];
  private hasEeocSection: boolean;
  private disableEeocQuickApply: boolean;

  private educationAutofiller: EducationAutofiller;
  private employmentAutoFiller: EmploymentAutofiller;
  private metadata?: TrackingMetadata;
  private tracker: SnowplowTracker | null;

  constructor(
    answers: Record<string, FieldValue>,
    setAnswers: (answers: Record<string, FieldValue>) => void,
    questions: Question[],
    educationAutoFiller: EducationAutofiller,
    hasEeocSection: boolean,
    disableEeocQuickApply: boolean,
    employmentAutoFiller: EmploymentAutofiller,
    metadata?: TrackingMetadata,
    tracker: SnowplowTracker | null = null
  ) {
    this.answers = answers;
    this.setAnswers = setAnswers;
    this.questions = questions;
    this.hasEeocSection = hasEeocSection;
    this.disableEeocQuickApply = disableEeocQuickApply;

    this.educationAutofiller = educationAutoFiller;
    this.employmentAutoFiller = employmentAutoFiller;
    this.metadata = metadata;
    this.tracker = tracker;
  }

  async fill(
    profile: QuickApplyProfile,
    setStatus: Dispatch<SetStateAction<"success" | "error" | null>>
  ): Promise<void> {
    if (invalidProfile(profile)) {
      setStatus("error");
      rollbar.error("Invalid profile for autofill", { profile });
      return;
    }

    try {
      this.fillAnswers(profile);
      this.employmentAutoFiller.fill(profile);
      await this.educationAutofiller.fill(profile);
      this.trackSuccess(profile);
      setStatus("success");
    } catch (e: any) {
      rollbar.error("Failed to autofill", e);
      setStatus("error");
    }
  }

  private fillAnswers(profile: QuickApplyProfile) {
    const newAnswers = {
      ...this.answers,
      first_name: this.chooseAnswer("first_name", profile.firstName),
      last_name: this.chooseAnswer("last_name", profile.lastName),
      email: this.chooseAnswer("email", profile.email),
      phone: this.chooseAnswer("phone", profile.phoneNumber),
      resume: this.chooseResumeAnswer(profile.resume),
    } as typeof this.answers;

    // Location is not always included in the form, so we should only set it if the field exists
    if (this.hasLocationField) {
      newAnswers.location = this.chooseAnswer("location", profile.location?.displayName);
      newAnswers.latitude = this.chooseAnswer("latitude", profile.location?.latitude.toString());
      newAnswers.longitude = this.chooseAnswer("longitude", profile.location?.longitude.toString());
    }

    // Preferred name is not always included in the form, so we should only set it if the field exists
    if (this.hasPreferredNameField) {
      newAnswers.preferred_name = this.chooseAnswer("preferred_name", profile.preferredName);
    }

    this.fillWebsites(profile, newAnswers);

    if (this.hasEeocSection && !this.disableEeocQuickApply && profile.selfIdentification) {
      const selfIdentification = profile.selfIdentification;
      newAnswers.disability_status = this.chooseAnswer(
        "disability_status",
        selfIdentification.disabilityStatus?.toString()
      );
      newAnswers.gender = this.chooseAnswer("gender", selfIdentification.gender?.toString());
      newAnswers.race = this.chooseAnswer("race", selfIdentification.race?.toString());
      newAnswers.veteran_status = this.chooseAnswer(
        "veteran_status",
        selfIdentification.veteranStatus?.toString()
      );
    }

    this.setAnswers(newAnswers);
  }

  private chooseAnswer(key: string, autofillAnswer?: string | null) {
    const existingAnswer = this.answers[key];

    if (typeof existingAnswer === "object" && existingAnswer != null) {
      return existingAnswer;
    }

    return existingAnswer?.trim() || autofillAnswer || "";
  }

  private fillWebsites(profile: QuickApplyProfile, newAnswers: Record<string, FieldValue>): void {
    const websites = profile.websites;

    if (!websites) {
      return;
    }

    (Object.keys(websites) as (keyof typeof websites)[]).forEach((key) => {
      const question = this.findQuestionByTitles(WEBSITE_FIELD_LABELS[key]);
      if (question) {
        const questionKey = question.fields[0].name;
        newAnswers[questionKey] = this.chooseAnswer(questionKey, websites[key]);
      }
    });
  }

  private findQuestionByTitles(titles: string[]): Question | undefined {
    const lowercaseTitles = titles.map((title) => title.toLowerCase());
    return this.questions.find((question) =>
      lowercaseTitles.includes(question.label.toLowerCase())
    );
  }

  private chooseResumeAnswer(resume: Resume): FieldValue {
    if (!resume) {
      return this.answers.resume;
    }

    if (this.answers.resume || this.answers.resume_text) {
      return this.answers.resume;
    }

    return resume;
  }

  private get hasLocationField(): boolean {
    return this.questions.some((question) => question.fields[0]?.name === "candidate_location");
  }

  private get hasPreferredNameField(): boolean {
    return this.questions.some((question) => question.fields[0]?.name === "preferred_name");
  }

  private trackSuccess(profile: QuickApplyProfile): void {
    if (!this.tracker || !this.metadata) {
      return;
    }

    this.tracker.track({
      name: "quick_apply_autofill_success",
      data: {
        user_id: profile.id,
        timestamp: Date.now(),
        job_post_id: this.metadata.jobPostId,
      },
    });
  }
}
