import { useState } from "react";
import { TextInput } from "~/components/text_input";
import { type FieldProps } from "~/components/job_posts/field";
import { Link } from "~/components/typography";
import { useBoardConfiguration } from "~/hooks/use_board_configuration";
import { fetchEmailSuggestion } from "~/utils/email_address_validator";
import { useTranslation } from "react-i18next";

const EmailField = ({ label, name, required, error, onChange, onFocus, value }: FieldProps) => {
  const [suggestedEmail, setSuggestedEmail] = useState<string | null>(null);
  const { outside_label: outsideLabel } = useBoardConfiguration();
  const { t } = useTranslation("job_post");

  const checkForSuggestion = async () => {
    if (typeof value !== "string") {
      return;
    }

    if (!value || value.length < 5) {
      setSuggestedEmail(null);
      return;
    }

    try {
      const suggestion = await fetchEmailSuggestion(value);
      setSuggestedEmail(suggestion);
    } catch (e) {
      console.error(`Failed to fetch email suggestion: ${e}`);
      setSuggestedEmail(null);
    }
  };

  const useSuggestion = () => {
    if (!suggestedEmail) {
      return;
    }

    onChange(suggestedEmail);
    setSuggestedEmail(null);
  };

  const prompt = () => {
    return (
      <>
        {t("application.email_suggestion_prompt")}{" "}
        <Link href="#" onClick={useSuggestion} data-testid="email-suggestion">
          {suggestedEmail}
        </Link>
      </>
    );
  };

  const helpMessage = () => {
    if (!suggestedEmail || error) {
      return null;
    }

    return prompt();
  };

  const errorMessage = () => {
    if (!error) {
      return null;
    }

    if (!suggestedEmail) {
      return error;
    }

    return (
      <>
        {error}
        <br />
        {prompt()}
      </>
    );
  };

  return (
    <TextInput
      id={name}
      label={label}
      required={required}
      onChange={(event) => onChange(event.currentTarget.value || "")}
      onBlur={checkForSuggestion}
      onFocus={onFocus}
      help={helpMessage()}
      error={errorMessage()}
      maxLength={255}
      outsideLabel={outsideLabel}
      value={(value as string) || ""}
      autoComplete="email"
    />
  );
};

export default EmailField;
