import { type Education } from "~/types/jben/education";
import { fetchResource } from "./fetch_resource";

type Options = {
  type: "schools" | "degrees" | "disciplines";
  urlToken: string;
  term?: string;
  page?: number;
};

interface Result {
  schools?: Education[];
  degrees?: Education[];
  disciplines?: Education[];
  status: number;
}

export async function fetchEducation({ type, urlToken, term, page = 1 }: Options): Promise<Result> {
  const jbenUrl = window?.ENV?.JBEN_URL;

  if (!jbenUrl) {
    throw new Error("JBEN_URL is not defined");
  }

  const path = `v1/boards/${urlToken}/education/${type}`;

  const url = new URL(`${jbenUrl}/${path}`);

  if (term) {
    url.searchParams.append("term", term);
  }

  if (page >= 1) {
    url.searchParams.append("page", page.toString());
  }

  const response = await fetchResource({
    url: url.toString(),
    key: type,
  });

  const items: Education[] = response[type]?.items || [];

  return {
    [type]: items,
    status: response.status,
  };
}
