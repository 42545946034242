import { useTranslation } from "react-i18next";
import "./job_posts.scss";
import { Body, SecondaryBody, SectionHeader } from "./typography";
import Table, { type Cell } from "./table";
import { type BoardJobPost } from "~/types/jben/board";
import { buildJobPostTags } from "~/utils/job_helpers";
import { useHiringTeamRenderer } from "~/hooks/use_hiring_team_renderer";
import { useBoardConfiguration } from "~/hooks/use_board_configuration";
import { useTrackingParams } from "~/hooks/use_tracking_params";
import { Fragment } from "react";

type Department = {
  id: number | undefined;
  name: string;
  path: string | undefined;
  jobPosts: Array<BoardJobPost>;
};

const JobPosts = ({
  jobPosts = [],
  internalBoard = false,
  filtered,
}: {
  jobPosts: Array<BoardJobPost>;
  internalBoard?: boolean;
  filtered?: boolean;
}) => {
  let { t } = useTranslation("board");
  const {
    display_hiring_team,
    display_department_hierarchy,
    display_departments: jben_display_departments,
  } = useBoardConfiguration();
  const displayDepartments = jben_display_departments !== false;
  const { buildRecruiterText, buildHiringManagerText } = useHiringTeamRenderer();
  const { withTrackingParams } = useTrackingParams();
  const shouldDisplayHiringTeam = display_hiring_team && internalBoard;

  if (jobPosts.length === 0) {
    return (
      <div className="job-posts__empty-state">
        {filtered ? (
          <>
            <Body metadata>{t("job_posts.no_jobs_matching_filter")}</Body>
            <SecondaryBody metadata>{t("job_posts.change_filters")}</SecondaryBody>
          </>
        ) : (
          <Body metadata>{t("job_posts.no_job_posts")}</Body>
        )}
      </div>
    );
  }

  const headers: string[] = [];
  // Only include headers if there will be > 1 column
  if (shouldDisplayHiringTeam) {
    headers.push(t("job_posts.job_header"));
    headers.push(t("job_posts.contacts_header"));
  }

  const buildRows = (jobPosts: BoardJobPost[]) => {
    return jobPosts.map((jobPost) => {
      const cells: Cell[] = [
        {
          primaryBody: jobPost.title,
          emphasis: true,
          secondaryBody: jobPost.location,
          href: withTrackingParams(jobPost.absolute_url),
          tags: buildJobPostTags(jobPost),
        },
      ];

      if (shouldDisplayHiringTeam) {
        const hiringManagerText = buildHiringManagerText(
          jobPost.hiring_team_members?.hiring_managers || []
        );
        const recruiterText = buildRecruiterText(jobPost.hiring_team_members?.recruiters || []);

        cells.push({
          primaryBody: hiringManagerText || "",
          secondaryBody: recruiterText || "",
          href: withTrackingParams(jobPost.absolute_url),
        });
      }

      return cells;
    });
  };

  const renderJobPosts = (rows: Cell[][]) => {
    return (
      <div className="job-posts--table">
        <Table
          rows={rows}
          headers={headers}
          rowClassName="job-post"
          defaultHeader={t("job_posts.job_header")}
        />
      </div>
    );
  };

  const renderDepartments = () => {
    const departments: Department[] = [];

    jobPosts.forEach((jobPost) => {
      const postUnder = jobPost.department || jobPost.section;
      const department = departments.find((department) => department.id === postUnder?.id);

      if (department) {
        department.jobPosts.push(jobPost);
      } else {
        departments.push({
          id: jobPost.department?.id || jobPost.section?.id,
          name: jobPost.department?.name || jobPost.section?.name || t("no_department"),
          path: jobPost.department?.path.join(" / "),
          jobPosts: [jobPost],
        });
      }
    });

    return departments.map((department) => (
      <div className="job-posts--table--department" key={department.id || -1}>
        {display_department_hierarchy && department.path && (
          <div className="job-posts--department-path">
            <SecondaryBody metadata>{department.path}</SecondaryBody>
          </div>
        )}
        <SectionHeader>{department.name}</SectionHeader>
        {renderJobPosts(buildRows(department.jobPosts))}
      </div>
    ));
  };

  return (
    <div className="job-posts">
      {displayDepartments ? renderDepartments() : renderJobPosts(buildRows(jobPosts))}
    </div>
  );
};

export default JobPosts;
