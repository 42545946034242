import { type EducationAnswer } from "~/components/job_posts/education_section";
import { type EducationConfiguration } from "~/types/jben/job_post";
import { type QuickApplyProfile } from "../types";
import { type Option } from "~/components/select";
import { fetchEducation } from "~/utils/fetch_education";

export class EducationAutofiller {
  private answers: EducationAnswer[];
  private educationConfiguration: Partial<EducationConfiguration>;
  private monthOptions: Option[];
  private setAnswers: (answers: EducationAnswer[]) => void;
  private urlToken: string;

  constructor(
    urlToken: string,
    answers: EducationAnswer[],
    setAnswers: (answers: EducationAnswer[]) => void,
    monthOptions: Option[],
    educationConfiguration?: EducationConfiguration
  ) {
    this.answers = answers;
    this.educationConfiguration = educationConfiguration || {};
    this.setAnswers = setAnswers;
    this.urlToken = urlToken;
    this.monthOptions = monthOptions;
  }

  async fill(profile: QuickApplyProfile): Promise<void> {
    if (!this.shouldFill) {
      return;
    }

    const newAnswers = await this.profileToAnswers(profile);

    if (newAnswers.length === 0) {
      return;
    }

    this.setAnswers(newAnswers);
  }

  private async profileToAnswers(profile: QuickApplyProfile): Promise<EducationAnswer[]> {
    return await Promise.all(
      profile.educations?.map(async (education, index) => {
        const { degree, discipline, endMonth, endYear, school, startMonth, startYear } = education;

        const [schoolAnswer, degreeAnswer, disciplineAnswer] = await Promise.all([
          this.findOption("schools", school),
          this.findOption("degrees", degree),
          this.findOption("disciplines", discipline),
        ]);

        // Base the key on the length of the existing answers array to account
        // for cases where the user has already clicked the add another button.
        const key = this.answers.length + index;

        return {
          key,
          degree: this.maybeAnswer("degree", degreeAnswer),
          discipline: this.maybeAnswer("discipline", disciplineAnswer),
          end_month: this.maybeAnswer("end_month", this.findMonthOption(endMonth)),
          end_year: this.maybeAnswer("end_year", endYear),
          school: this.maybeAnswer("school_name", schoolAnswer),
          start_month: this.maybeAnswer("start_month", this.findMonthOption(startMonth)),
          start_year: this.maybeAnswer("start_year", startYear),
        };
      }) || []
    );
  }

  private async findOption(
    type: "schools" | "degrees" | "disciplines",
    name?: string | null
  ): Promise<Option | undefined> {
    if (!name) {
      return undefined;
    }

    const searchResult = await fetchEducation({ type, urlToken: this.urlToken, term: name });

    if (searchResult.status !== 200) {
      return undefined;
    }

    const potentialAnswers = searchResult[type] || [];

    const answer = potentialAnswers.find((answer) => answer.text === name);

    if (!answer) {
      return undefined;
    }

    return { label: answer.text, value: answer.id.toString() };
  }

  private findMonthOption(month?: number | null): Option | undefined {
    if (!month) {
      return undefined;
    }

    return this.monthOptions.find((option) => option.value.toString() === month.toString());
  }

  private maybeAnswer<T>(key: keyof EducationConfiguration, value?: T | null): T | undefined {
    if (!value || !this.fieldAvailable(key)) {
      return undefined;
    }

    return value;
  }

  private fieldAvailable(key: keyof EducationConfiguration): boolean {
    return (
      this.educationConfiguration[key] === "optional" ||
      this.educationConfiguration[key] === "required"
    );
  }

  private get shouldFill(): boolean {
    if (!this.hasEducationQuestions) {
      return false;
    }

    // If the user has manually provided ANY education answer, then we should not autofill
    return !this.answers.some((answer) => {
      // The key property is only used to identify the answers, so we should ignore
      // it when determining if the user has manually provided an answer.
      const { key: _key, ...attributes } = answer;

      // If any key has a value, then the user has manually provided an answer.
      return Object.keys(attributes).some((key) => !!attributes[key as keyof typeof attributes]);
    });
  }

  private get hasEducationQuestions(): boolean {
    return (Object.keys(this.educationConfiguration) as (keyof EducationConfiguration)[]).some(
      (key) => this.fieldAvailable(key)
    );
  }
}
