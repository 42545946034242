import type { Application, DemographicAnswerOption } from "~/types/jben/application";
import type { EducationConfiguration, JobPost, Question } from "~/types/jben/job_post";
import { isStandardKey, keyToQuestionId } from "./build_application";
import {
  anyDemographicQuestionAnswered,
  anyDemographicQuestionRequired,
} from "~/utils/demographic_data_consent";
import { withNamespace } from "~/utils/translation";
import { employmentRequired } from "./job_helpers";

export interface EducationErrors {
  school?: string | null;
  degree?: string | null;
  discipline?: string | null;
  start_month?: string | null;
  start_year?: string | null;
  end_month?: string | null;
  end_year?: string | null;
}

export interface ErrorWithLink {
  message: string;
  linkText: string;
  linkTarget: string;
}

export interface ValidationResult {
  isValid: boolean;
  basicErrors: Record<string, string>;
  demographicErrors: Record<string, string>;
  complianceErrors: Record<string, string | ErrorWithLink>;
  employmentErrors: Record<string, string>[];
  educationErrors: Record<number, EducationErrors>;
  eeocErrors: Record<string, string>;
}

function hasError(
  obj:
    | Record<string, string>
    | Record<string, string | ErrorWithLink>
    | Record<number, EducationErrors>
): boolean {
  return Object.keys(obj).length > 0;
}

function arrayHasError(array: Record<string, string>[]): boolean {
  return array.some((item) => hasError(item));
}

function isCustomQuestion(question: Question): boolean {
  return question.fields[0].name.startsWith("question_");
}

function getRequiredQuestions(jobPost: JobPost) {
  if (!jobPost.questions) {
    return [];
  }

  return jobPost.questions.filter((question) => question.required);
}

function getRequiredDemographicQuestions(jobPost: JobPost) {
  if (!jobPost.demographic_questions) {
    return [];
  }

  return jobPost.demographic_questions.questions.filter((question) => question.required);
}

function getRequiredEeocQuestions(jobPost: JobPost) {
  if (!jobPost.eeoc_sections) {
    return [];
  }

  return jobPost.eeoc_sections.flatMap((section) =>
    section.questions.filter((question) => question.required)
  );
}

function validateBasicQuestions(
  jobPost: JobPost,
  application: Application,
  locationServiceUnavailable: boolean
): Record<string, string> {
  const t = withNamespace("job_post", { prefix: "application" });

  const result: Record<string, string> = {};

  const requiredQuestions = getRequiredQuestions(jobPost);

  for (const question of requiredQuestions) {
    const field = question.fields[0];

    if (isCustomQuestion(question)) {
      const questionId = keyToQuestionId(field.name);

      if (!questionId) {
        continue;
      }

      let hasValue = false;

      const answer = application.answers_attributes[questionId] || {};

      if (field.type === "input_file") {
        hasValue =
          !!application.attachments[`${questionId}_url`] &&
          !!application.attachments[`${questionId}_url_filename`];
      } else if (field.type === "input_text" || field.type === "textarea") {
        hasValue = !!answer.text_value?.trim();
      } else if (field.type === "multi_value_single_select" && "boolean_value" in answer) {
        // Yes/Nos have a type of multi_value_single_select but are backed by boolean_value
        hasValue = answer.boolean_value === 0 || answer.boolean_value === 1;
      } else if (
        field.type === "multi_value_multi_select" ||
        field.type === "multi_value_single_select"
      ) {
        hasValue = Object.keys(answer.answer_selected_options_attributes ?? {}).length > 0;
      } else {
        hasValue = !!application.answers_attributes[questionId];
      }

      if (!hasValue) {
        result[field.name] = t("field_is_required");
      }

      continue;
    }

    if (field.name === "resume" || field.name === "cover_letter") {
      const hasValue =
        (!!application[`${field.name}_url`] && !!application[`${field.name}_url_filename`]) ||
        !!application[`${field.name}_text`];

      if (!hasValue) {
        result[field.name] = t("is_required", {
          interpolation: { escapeValue: false },
          fieldName: question.label,
        });
      }

      continue;
    }

    if (field.name === "candidate_location") {
      const hasLocationValue =
        application.location && application.latitude && application.longitude;

      if (!hasLocationValue && !locationServiceUnavailable) {
        result["candidate_location"] = t("enter_location");
      }

      continue;
    }

    if (!isStandardKey(field.name)) {
      continue;
    }

    const value = application[field.name];

    if (!value) {
      result[field.name] = t("is_required", { fieldName: question.label });
    } else {
      if (field.name === "email") {
        const emailRegex = /^([a-zA-Z0-9_.\-+'])*[\w+]@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!emailRegex.test(value)) {
          result[field.name] = t("invalid_email");
        }
      }
    }
  }

  return result;
}

function validateDemographicQuestions(
  jobPost: JobPost,
  application: Application
): Record<string, string> {
  const t = withNamespace("job_post", { prefix: "application" });

  const result: Record<string, string> = {};

  const requiredDemographicQuestions = getRequiredDemographicQuestions(jobPost);

  const answerLookup = application.demographic_answers.reduce((previous, current) => {
    previous[current.question_id] = current.answer_options;

    return previous;
  }, {} as Record<number, DemographicAnswerOption[]>);

  for (const question of requiredDemographicQuestions) {
    const value = answerLookup[question.id];

    if (!value || value.length < 1) {
      result[question.id.toString()] = t("field_is_required");
    }
  }

  return result;
}

function validateComplianceQuestions(jobPost: JobPost, application: Application) {
  const retention_or_processing_consent_required =
    jobPost.data_compliance?.requires_processing_consent ||
    jobPost.data_compliance?.requires_retention_consent;

  const t = withNamespace("job_post", { prefix: "application" });

  const result: Record<string, string> = {};

  if (retention_or_processing_consent_required) {
    if (
      jobPost.data_compliance?.single_purpose_consent &&
      jobPost.data_compliance.requires_processing_consent &&
      application.data_compliance?.gdpr_processing_consent_given !== true
    ) {
      result["gdpr_processing_consent_given"] = t("accept_terms");
    } else if (
      !jobPost.data_compliance?.single_purpose_consent &&
      application.data_compliance.gdpr_consent_given !== true
    ) {
      result["gdpr_consent_given"] = t("accept_terms");
    }
  }

  if (
    jobPost.data_compliance?.single_purpose_consent &&
    jobPost.data_compliance?.demographic_data_consent
  ) {
    return {
      ...result,
      ...validateDemographicDataComplianceQuestions(jobPost, application),
    };
  }

  return result;
}

function validateDemographicDataComplianceQuestions(jobPost: JobPost, application: Application) {
  if (application.data_compliance.gdpr_demographic_data_consent_given) {
    return {};
  }

  const t = withNamespace("job_post", { prefix: "application" });

  const result: Record<string, string | ErrorWithLink> = {};

  if (anyDemographicQuestionRequired(jobPost.demographic_questions?.questions)) {
    result["gdpr_demographic_data_consent_given"] = t("accept_terms") as string;
  } else if (anyDemographicQuestionAnswered(application.demographic_answers)) {
    result["gdpr_demographic_data_consent_given"] = {
      message: t("demographic_questions_answered.message"),
      linkText: t("demographic_questions_answered.link_text"),
      linkTarget: "#demographic-section",
    };
  }

  return result;
}

function validateEmploymentQuestions(jobPost: JobPost, application: Application) {
  const required = employmentRequired(jobPost);

  const t = withNamespace("job_post", { prefix: "employment" });

  return (
    application.employments?.map((employment) => {
      const result: Record<string, string> = {};

      const partiallyFilledOut =
        (employment.company_name && employment.company_name.length > 0) ||
        (employment.title && employment.title.length > 0) ||
        (employment.start_date?.month && employment.start_date?.year) ||
        (employment.end_date?.month && employment.end_date?.year);

      if (required || partiallyFilledOut) {
        if (!employment.company_name || employment.company_name === "") {
          result["company_name"] = t("company_name_is_required");
        }
        if (!employment.title || employment.title === "") {
          result["title"] = t("title_is_required");
        }
        if (!employment.start_date?.month) {
          result["start_date_month"] = t("start_date_month_is_required");
        }
        if (!employment.start_date?.year) {
          result["start_date_year"] = t("start_date_year_is_required");
        }
        if (!employment.current) {
          if (!employment.end_date?.month) {
            result["end_date_month"] = t("end_date_month_is_required");
          }
          if (!employment.end_date?.year) {
            result["end_date_year"] = t("end_date_year_is_required");
          }
        }
      }

      if (
        employment.start_date?.year &&
        (Number(employment.start_date?.year) > 2100 || Number(employment.start_date?.year) < 1900)
      ) {
        result["start_date_year"] = t("invalid_year");
      }
      if (
        employment.end_date?.year &&
        (Number(employment.end_date?.year) > 2100 || Number(employment.end_date?.year) < 1900)
      ) {
        result["end_date_year"] = t("invalid_year");
      }
      if (
        employment.start_date?.year &&
        employment.start_date?.month &&
        employment.end_date?.year &&
        employment.end_date?.month
      ) {
        if (
          !employment.current &&
          new Date(employment.start_date?.year, employment.start_date?.month) >
            new Date(employment.end_date?.year, employment.end_date?.month)
        ) {
          result["end_date_year"] = t("end_date_must_be_after_start_date");
        }
        if (new Date(employment.end_date?.year, employment.end_date?.month) > new Date()) {
          result["end_date_year"] = t("end_date_must_be_past");
        }
      }

      return result;
    }) || []
  );
}

function validateEducationQuestions(
  educationConfiguration: EducationConfiguration | undefined,
  application: Application
): Record<number, EducationErrors> {
  if (!application.educations || !educationConfiguration) {
    return {};
  }

  const t = withNamespace("job_post", { prefix: "education.validation" });

  const result: Record<number, EducationErrors> = {};

  for (let i = 0; i < application.educations.length; i++) {
    const education = application.educations[i];
    const errors: EducationErrors = {};

    if (
      education.start_date?.year &&
      (education.start_date?.year < 1900 || education.start_date?.year > 2100)
    ) {
      errors.start_year = t("invalid_year");
    }

    if (
      education.end_date?.year &&
      (education.end_date?.year < 1900 || education.end_date?.year > 2100)
    ) {
      errors.end_year = t("invalid_year");
    }

    if (
      education.start_date?.year &&
      education.end_date?.year &&
      new Date(education.start_date?.year, education.start_date?.month || 0) >
        new Date(education.end_date?.year, education.end_date?.month || 0)
    ) {
      errors.end_year = t("end_date_after_start_date");
    }

    if (!education.school_name_id && educationConfiguration.school_name === "required") {
      errors.school = t("required.school");
    }

    if (!education.degree_id && educationConfiguration.degree === "required") {
      errors.degree = t("required.degree");
    }

    if (!education.discipline_id && educationConfiguration.discipline === "required") {
      errors.discipline = t("required.discipline");
    }

    if (!education.start_date.month && educationConfiguration.start_month === "required") {
      errors.start_month = t("required.start_month");
    }

    if (!education.start_date.year && educationConfiguration.start_year === "required") {
      errors.start_year = t("required.start_year");
    }

    if (!education.end_date.month && educationConfiguration.end_month === "required") {
      errors.end_month = t("required.end_month");
    }

    if (!education.end_date.year && educationConfiguration.end_year === "required") {
      errors.end_year = t("required.end_year");
    }

    if (hasError(errors as Record<string, string>)) {
      result[i] = errors;
    }
  }

  return result;
}

function validateEeocQuestions(jobPost: JobPost, application: Application) {
  if (!jobPost.eeoc_sections) {
    return {};
  }

  const t = withNamespace("job_post", { prefix: "application" });

  const result: Record<string, string> = {};

  const requiredEeocQuestions = getRequiredEeocQuestions(jobPost);

  for (const question of requiredEeocQuestions) {
    const name = question.fields[0].name;

    if (!application[name]) {
      result[name] = t("field_is_required");
    }
  }

  return result;
}

export function validateApplication(
  jobPost: JobPost,
  application: Application,
  locationServiceUnavailable: boolean
): ValidationResult {
  const basicErrors: Record<string, string> = validateBasicQuestions(
    jobPost,
    application,
    locationServiceUnavailable
  );
  const demographicErrors: Record<string, string> = validateDemographicQuestions(
    jobPost,
    application
  );
  const complianceErrors: Record<string, string | ErrorWithLink> = validateComplianceQuestions(
    jobPost,
    application
  );
  const employmentErrors: Record<string, string>[] = validateEmploymentQuestions(
    jobPost,
    application
  );
  const educationErrors: Record<number, EducationErrors> = validateEducationQuestions(
    jobPost.education_config,
    application
  );
  const eeocErrors: Record<string, string> = validateEeocQuestions(jobPost, application);

  return {
    isValid:
      !hasError(basicErrors) &&
      !hasError(demographicErrors) &&
      !hasError(complianceErrors) &&
      !arrayHasError(employmentErrors) &&
      !hasError(educationErrors) &&
      !hasError(eeocErrors),
    basicErrors,
    demographicErrors,
    complianceErrors,
    educationErrors,
    employmentErrors,
    eeocErrors,
  };
}
