import { Checkbox } from "../checkbox";
import type { Option } from "../select";
import { useTranslation } from "react-i18next";
import type { ComplianceAnswers, DataCompliance } from "~/types/jben/job_post";
import type { ErrorWithLink } from "~/utils/validate_application";
import "./compliance_section.scss";

export interface ComplianceSectionProps {
  internal?: boolean;
  data: DataCompliance;
  complianceAnswers: ComplianceAnswers;
  errors: Record<string, string | ErrorWithLink>;
  companyName: string;
  onChange: (key: keyof ComplianceAnswers, value: boolean) => void;
  demographicDataConsentRequired: boolean;
}

export const ComplianceSection = ({
  internal,
  complianceAnswers,
  data,
  errors,
  companyName,
  onChange,
  demographicDataConsentRequired,
}: ComplianceSectionProps) => {
  const { t } = useTranslation("job_post");

  const handleSelect = (key: keyof ComplianceAnswers, selected: Option | Option[]) => {
    if (Array.isArray(selected)) {
      const value = selected.length > 0 ? true : false;
      onChange(key, value);
    }
  };

  if (!data) {
    return null;
  }

  const retentionConsentDisabled =
    data.requires_processing_consent && !complianceAnswers["gdpr_processing_consent_given"];

  return (
    <>
      {"gdpr_processing_consent_given" in complianceAnswers && (
        <Checkbox
          isMulti
          internal={internal}
          error={errors["gdpr_processing_consent_given"]}
          id={"gdpr_processing_consent_given"}
          required={true}
          options={[
            {
              value: "1",
              label: t("compliance.processing_consent", {
                company_name: companyName,
                interpolation: { escapeValue: false },
              }),
              required: true,
            },
          ]}
          onSelect={(selected) => handleSelect("gdpr_processing_consent_given", selected)}
          className="checkbox--full-width"
        />
      )}
      {"gdpr_retention_consent_given" in complianceAnswers && (
        <Checkbox
          isMulti
          internal={internal}
          error={errors["gdpr_retention_consent_given"]}
          id={"gdpr_retention_consent_given"}
          options={[
            {
              value: "1",
              label: t("compliance.retention_consent", {
                company_name: companyName,
                retention_period: data.retention_period,
                interpolation: { escapeValue: false },
              }),
              required: false,
            },
          ]}
          values={complianceAnswers["gdpr_retention_consent_given"] ? ["1"] : []}
          onSelect={(selected) => handleSelect("gdpr_retention_consent_given", selected)}
          disabled={retentionConsentDisabled}
          className="checkbox--full-width"
        />
      )}
      {"gdpr_consent_given" in complianceAnswers && (
        <Checkbox
          isMulti
          internal={internal}
          error={errors["gdpr_consent_given"]}
          id={"gdpr_consent_given"}
          required={true}
          options={[
            {
              value: "1",
              label: t("compliance.gdpr_consent", {
                company_name: companyName,
                retention_period: data.retention_period,
                interpolation: { escapeValue: false },
              }),
              required: true,
            },
          ]}
          onSelect={(selected) => handleSelect("gdpr_consent_given", selected)}
          className="checkbox--full-width"
        />
      )}

      {"gdpr_demographic_data_consent_given" in complianceAnswers && (
        <Checkbox
          isMulti
          internal={internal}
          error={errors["gdpr_demographic_data_consent_given"]}
          id={"gdpr_demographic_data_consent_given"}
          required={demographicDataConsentRequired}
          options={[
            {
              value: "1",
              label: t("compliance.demographic_data_consent", {
                company_name: companyName,
                interpolation: { escapeValue: false },
              }),
              required: demographicDataConsentRequired,
            },
          ]}
          onSelect={(selected) => handleSelect("gdpr_demographic_data_consent_given", selected)}
          className="checkbox--full-width"
        />
      )}
    </>
  );
};
