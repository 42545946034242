import { useState, useEffect } from "react";
import type { BoardConfiguration } from "~/types/board_configuration";
import { useLocation } from "@remix-run/react";
import { isNewPreviewRoute } from "~/utils/route_helpers";
import { Resizer } from "~/utils/resizer";

export const usePreviewableBoardConfiguration = (
  initialBoardConfiguration: BoardConfiguration | null
) => {
  const [boardConfiguration, setBoardConfiguration] = useState<BoardConfiguration | null>(
    initialBoardConfiguration
  );

  const { pathname } = useLocation();
  const isNewPreview = isNewPreviewRoute(pathname);

  useEffect(() => {
    if (!isNewPreview) {
      return;
    }

    const updateBoardConfiguration = (event: any) => {
      if (typeof event.data !== "object" || event.data.type !== "update_board_configuration") {
        return;
      }

      const newConfiguration = event.data.payload;
      setBoardConfiguration((oldConfiguration) => {
        if (
          newConfiguration.primary_font &&
          newConfiguration.primary_font !== oldConfiguration?.primary_font
        ) {
          newConfiguration.primary_font_fallback = oldConfiguration?.primary_font;
        }
        if (
          newConfiguration.secondary_font &&
          newConfiguration.secondary_font !== oldConfiguration?.secondary_font
        ) {
          newConfiguration.secondary_font_fallback = oldConfiguration?.secondary_font;
        }
        return { ...oldConfiguration, ...newConfiguration };
      });
    };

    window.addEventListener("message", updateBoardConfiguration);

    return () => {
      window.removeEventListener("message", updateBoardConfiguration);
    };
  }, [isNewPreview]);

  useEffect(() => {
    Resizer.getInstance().handleResize();
  }, [boardConfiguration]);

  return boardConfiguration;
};
