import { isServer } from "~/utils/env_check";

// Redirect if not opted into internal embedded boards
export function useRedirectForEmbeddedBoards(
  internal: boolean,
  embedInternalBoard?: boolean | null
) {
  if (isServer()) {
    return;
  }

  if (internal && !embedInternalBoard) {
    window.location.assign("/");
  }
}
