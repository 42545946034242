import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import EeocQuestion from "~/components/job_posts/eeoc/question";
import type { Question } from "~/types/jben/job_post";
import type { FieldValue } from "../field";
import { Link } from "~/components/typography";
import serverAssetUrl from "~/utils/asset_url.server";
import { Resizer } from "~/utils/resizer";
import { serverOnly$ } from "vite-env-only/macros";
import type { OnSelectParam, Option } from "~/components/select";

interface Props {
  selected?: OnSelectParam;
  question: Question;
  error?: string;
  onChange: (key: string, value: FieldValue) => void;
}

const NO = "0";
const YES = "1";
const DECLINE = "2";

const HISPANIC_OR_LATINO_RACE = "4";
const DECLINE_TO_SELF_IDENTIFY_RACE = "8";

const EeocRaceQuestion = ({ question, selected, error, onChange }: Props) => {
  const [showRace, setShowRace] = useState(false);
  const [selectedOption, setSelectedOption] = useState<OnSelectParam | undefined>(undefined);
  const { t } = useTranslation("job_post");

  useEffect(() => {
    setSelectedOption(selected);
  }, [selected]);

  const hispanicEthnicityQuestion: Question = {
    label: t("eeoc.questions.hispanic_ethnicity.label"),
    required: question.required,
    description: null,
    fields: [
      {
        name: "hispanic_ethnicity",
        type: "multi_value_single_select",
        values: [YES, NO, DECLINE].map((value) => ({
          label: t(`eeoc.questions.hispanic_ethnicity.answers.${value}`),
          value: value,
        })),
      },
    ],
  };

  const findHispanicAnswer = (answer: string) => {
    return hispanicEthnicityQuestion.fields[0].values.find(
      (val) => val.value === answer
    ) as OnSelectParam;
  };

  const onHispanicChange = (key: string, value: FieldValue) => {
    if (value === YES) {
      onChange("race", HISPANIC_OR_LATINO_RACE);
      setShowRace(false);
    } else if (value === NO) {
      onChange("race", "");
      setShowRace(true);
      Resizer.getInstance().handleResize();
    } else if (value === DECLINE) {
      onChange("race", DECLINE_TO_SELF_IDENTIFY_RACE);
      setShowRace(false);
    } else {
      // Selection was cleared
      onChange("race", "");
      setShowRace(false);
    }
  };

  let hispanicAnswer: OnSelectParam = null;
  if (selected !== undefined) {
    if ((selected as Option).value === HISPANIC_OR_LATINO_RACE) {
      hispanicAnswer = findHispanicAnswer(YES);
    } else if ((selected as Option).value === DECLINE_TO_SELF_IDENTIFY_RACE) {
      hispanicAnswer = findHispanicAnswer(DECLINE);
    } else {
      hispanicAnswer = findHispanicAnswer(NO);
      if (!showRace) {
        setShowRace(true);
      }
    }
  }

  const assetUrl = serverOnly$(serverAssetUrl) || window.ENV.ASSET_URL;

  return (
    <>
      <EeocQuestion
        question={hispanicEthnicityQuestion}
        error={showRace ? "" : error}
        onChange={onHispanicChange}
        selected={hispanicAnswer}
      />

      {showRace && (
        <EeocQuestion
          question={question}
          error={error}
          onChange={onChange}
          excludeOption={HISPANIC_OR_LATINO_RACE}
          selected={selectedOption}
        />
      )}

      <Link
        href={`${assetUrl}${t("eeoc.links.race_ethnicity_definitions.href")}`}
        target="_blank"
        rel="noreferrer"
      >
        {t("eeoc.links.race_ethnicity_definitions.text")}
      </Link>
    </>
  );
};

export default EeocRaceQuestion;
