import type { QuickApplyProfile } from "./types";

export interface AutofillField {
  name: string;
  label?: string;
}

const STANDARD_FIELDS: Readonly<Set<string>> = Object.freeze(
  new Set([
    "first_name",
    "last_name",
    "email",
    "preferred_name",
    "phone",
    "candidate_location",
    "education",
    "employment",
  ])
);

export const WEBSITE_FIELD_LABELS = Object.freeze({
  github: ["GitHub", "GitHub Profile", "GitHub Link", "GitHub URL"],
  linkedin: ["LinkedIn", "LinkedIn Profile"],
  portfolio: [
    "Portfolio",
    "Website/Portfolio",
    "Website / Portfolio",
    "Portfolio link",
    "Portfolio URL",
  ],
  website: ["Website"],
});

const AUTOFILL_FIELD_LABELS = Object.freeze(
  Object.values(WEBSITE_FIELD_LABELS).reduce(
    (acc, labels) => acc.concat(labels.map((l) => l.toLowerCase())),
    []
  )
);

// This function should be updated as we add new supported fields to autofill.
// Any autofillable, focusable field should cause this function to return true.
export function shouldTriggerMagicAutofill(field: AutofillField): boolean {
  if (STANDARD_FIELDS.has(field.name)) {
    return true;
  }

  if (!field.label) {
    return false;
  }

  return AUTOFILL_FIELD_LABELS.includes(field.label.toLowerCase());
}

export function invalidProfile(profile: QuickApplyProfile): boolean {
  return !profile || Object.keys(profile).length === 0 || !hasNonEmptyValue(profile);
}

function hasNonEmptyValue(obj: any) {
  if (typeof obj !== "object" || obj === null) return false;

  for (let key in obj) {
    const value = obj[key];

    if (typeof value === "string" && value.trim() !== "") {
      return true;
    }
    if (Array.isArray(value) && value.length > 0) {
      return true;
    }
    if (typeof value === "object" && value !== null) {
      if (hasNonEmptyValue(value)) return true;
    }
    if (value !== null && typeof value !== "object") {
      return true;
    }
  }

  return false;
}
