interface UploadedFile {
  name: string;
  url: string;
}

function isUploadedFile(obj: any): obj is UploadedFile {
  if (!obj) {
    return false;
  }

  if (typeof obj !== "object") {
    return false;
  }

  if (!obj.hasOwnProperty("name") || !obj.hasOwnProperty("url")) {
    return false;
  }

  const castObj = obj as Partial<UploadedFile>;

  return !!castObj.name && !!castObj.url;
}

export { type UploadedFile, isUploadedFile };
