import type CsrfToken from "~/types/csrf_token";
import type { Application } from "~/types/jben/application";
import type Recaptcha from "~/utils/captcha/recaptcha";

interface Body {
  job_application: Application;
  [key: string]: any;
}

type Args = {
  application: Application;
  submitPath: string;
  csrfToken: CsrfToken | null;
  fingerprint: string;
  recaptchaClient?: Recaptcha;
  securityCode?: string;
  captchaFailed?: boolean;
};

export async function submitApplication({
  application,
  submitPath,
  csrfToken,
  fingerprint,
  recaptchaClient,
  securityCode,
  captchaFailed,
}: Args) {
  const body: Body = {
    job_application: application,
  };

  if (csrfToken) {
    body[csrfToken.key] = csrfToken.value;
  }

  if (recaptchaClient) {
    body["g-recaptcha-enterprise-token"] = await recaptchaClient.performAssessment();
  }

  if (securityCode) {
    body["security_code"] = securityCode;
  }

  if (captchaFailed) {
    body["captcha_retried"] = true;
  }

  return fetch(submitPath, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...body,
      fingerprint,
    }),
  });
}
