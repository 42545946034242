type Response = { did_you_mean: string | null };

export const fetchEmailSuggestion = async (email: string) => {
  const { EMAIL_ADDRESS_VALIDATOR_HOST } = window.ENV;
  if (!EMAIL_ADDRESS_VALIDATOR_HOST) {
    return null;
  }

  const url = new URL(`https://${EMAIL_ADDRESS_VALIDATOR_HOST}/address/validate`);
  url.searchParams.append("address", email);

  const response = await fetch(url.toString());
  const { status } = response;

  if (status < 200 || status >= 300) {
    const message = `Received HTTP ${status} calling ${url} for ${email}`;
    throw new Error(message);
  }

  const { did_you_mean: suggestion } = (await response.json()) as Response;
  return suggestion;
};
