import type { BoardConfiguration } from "~/types/board_configuration";
import { COLOR } from "~/types/ui";

const snakeCaseToTitleCase = (s: string | null | undefined) => {
  if (!s) {
    return "Untitled Sans";
  }

  return s.replace(/^_*(.)|_+(.)/g, (s, c, d) => (c ? c.toUpperCase() : " " + d.toUpperCase()));
};

export interface CustomCssProperties extends React.CSSProperties {
  "--custom-link-color": string | null | undefined;
  "--custom-active-field-color": string | null | undefined;
  "--custom-focus-color": string | null | undefined;
  "--custom-list-hover-color": string | null | undefined;
  "--custom-primary-typography-color": string | null | undefined;
  "--custom-button-color": string | null | undefined;
  "--custom-button-hover-color": string | null | undefined;
  "--custom-button-text-color": string | null | undefined;
  "--custom-primary-font-family": string | null | undefined;
  "--custom-secondary-font-family": string | null | undefined;
  "--custom-dropdown-selection-color": string | null | undefined;
  "--custom-secondary-typography-color": string | null | undefined;
  "--custom-secondary-typography-10": string | null | undefined;
  "--custom-secondary-typography-30": string | null | undefined;
  "--custom-secondary-typography-60": string | null | undefined;
  "--custom-background-color": string | null | undefined;
}

export function configuredStyles(
  config: BoardConfiguration | null,
  defaultColor: string,
  defaultPrimaryTypographyColor: string
): CustomCssProperties {
  const primaryFonts = [config?.primary_font, config?.primary_font_fallback]
    .filter(Boolean)
    .join(", ");
  const secondaryFonts = [config?.secondary_font, config?.secondary_font_fallback]
    .filter(Boolean)
    .join(", ");

  return {
    "--custom-link-color": config?.link_color || defaultColor,
    "--custom-active-field-color": config?.active_field_color || defaultColor,
    "--custom-focus-color": config?.active_field_color || defaultColor,
    "--custom-list-hover-color": config?.list_selection_color || `${defaultColor}40`,
    "--custom-primary-typography-color":
      config?.primary_font_color || defaultPrimaryTypographyColor,
    "--custom-button-color": config?.button_color || COLOR.blue,
    "--custom-button-hover-color": config?.button_hover_color || COLOR.buttonHover,
    "--custom-button-text-color": config?.button_text_color || COLOR.white,
    "--custom-primary-font-family": snakeCaseToTitleCase(primaryFonts),
    "--custom-secondary-font-family": snakeCaseToTitleCase(secondaryFonts),
    "--custom-dropdown-selection-color": config?.dropdown_selection_color,
    "--custom-secondary-typography-color": config?.secondary_font_color || "#262626",
    "--custom-secondary-typography-10": config?.secondary_font_color
      ? `${config.secondary_font_color}1A`
      : "#2626261A",
    "--custom-secondary-typography-30": config?.secondary_font_color
      ? `${config.secondary_font_color}4D`
      : "#2626264D",
    "--custom-secondary-typography-60": config?.secondary_font_color
      ? `${config.secondary_font_color}99`
      : "#26262699",
    "--custom-background-color": config?.background_color || "#FFFFFF",
  };
}
