import { GreenhouseLogo } from "./greenhouse_logo";
import { Body, Link } from "./typography";
import "./footer.scss";
import { useBoardConfiguration } from "~/hooks/use_board_configuration";

export const Footer = () => {
  const { preview_mode } = useBoardConfiguration();
  return (
    <footer className="footer">
      <div className="footer-row">
        <Body>Powered by</Body>
        {preview_mode ? (
          <button className="footer-logo-link">
            <GreenhouseLogo height={30} width={100} />
          </button>
        ) : (
          <a
            className="footer-logo-link"
            target="_blank"
            href={"https://www.greenhouse.io/privacy-policy"}
            rel="noreferrer"
          >
            <GreenhouseLogo height={30} width={100} />
          </a>
        )}
      </div>
      <div className="footer-row">
        <Body>
          Read our{" "}
          <Link
            target="_blank"
            href={preview_mode ? undefined : "https://www.greenhouse.io/privacy-policy"}
            rel="noreferrer"
          >
            Privacy Policy
          </Link>
        </Body>
      </div>
    </footer>
  );
};
