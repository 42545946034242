import { useState } from "react";
import classNames from "classnames";
import "./checkbox.scss";
import { Link } from "~/components/typography";
import CheckMarkIcon from "./icons/check_mark";
import { useBoardConfiguration } from "~/hooks/use_board_configuration";
import type { ErrorWithLink } from "~/utils/validate_application";

type Option = {
  label: string;
  value: string | number;
  required?: boolean;
};

interface CheckboxProps {
  id: string;
  options: Array<Option>;
  description?: string;
  isMulti?: boolean;
  required?: boolean;
  error?: string | ErrorWithLink | null;
  internal?: boolean;
  disabled?: boolean;
  onSelect: (selected: Option | Option[]) => void;
  values?: Array<string | number>;
  className?: string;
}

const SharedCheckbox = (props: CheckboxProps) => {
  const [multiSelect, setMultiSelect] = useState<Option[]>([]);
  const {
    id,
    options = [],
    isMulti = false,
    error,
    internal,
    disabled,
    onSelect,
    values,
    ...remainingProps
  } = props;
  const { active_field_color } = useBoardConfiguration();

  const handleMultiSelect = (options: Option[]) => {
    onSelect(options);
    setMultiSelect(options);
  };

  const renderError = (error: string | ErrorWithLink) => {
    return (
      <p id={`${id}-error`} className="helper-text helper-text--error" aria-live="polite">
        {typeof error === "string" && error}

        {typeof error !== "string" && (
          <>
            {error.message}
            &nbsp;
            <Link href={error.linkTarget}>{error.linkText}</Link>
          </>
        )}
      </p>
    );
  };

  return (
    <>
      {options.map((option, idx) => {
        const optionId = `${id}_${option.value}`;

        // If values are provided, state of checkbox(es) will be controlled by parent
        let checkedProps: { checked?: boolean } = {};
        if (values) {
          checkedProps["checked"] = values.includes(option.value);
        }

        return (
          <div key={"option-" + idx} className="checkbox__wrapper">
            <div className="checkbox__input">
              <input
                {...remainingProps}
                {...checkedProps}
                type={isMulti ? "checkbox" : "radio"}
                id={optionId}
                name={id}
                value={option.value.toString()}
                disabled={disabled}
                aria-describedby={`${id}-description ${id}-error`}
                aria-invalid={!!error}
                aria-errormessage={`${id}-error`}
                onChange={(event) => {
                  const selected = { value: event.target.value, label: option.label };
                  const filteredValues = multiSelect.filter(
                    (value) => String(value.value) !== event.target.value
                  );

                  if (isMulti) {
                    const value = event.target.checked
                      ? [...multiSelect, selected]
                      : filteredValues;
                    handleMultiSelect(value);
                    return;
                  }

                  onSelect(selected);
                }}
              />
              <CheckMarkIcon
                size="xs"
                viewBox="0 0 16 16"
                color={active_field_color || (internal ? "green" : "blue")}
              />
            </div>
            <label htmlFor={optionId}>
              {option.label}
              {option.required && <span className="required">*</span>}
            </label>
          </div>
        );
      })}
      {error && renderError(error)}
    </>
  );
};

export const MultiCheckbox = (props: CheckboxProps) => {
  const { id, required = false, description, error, disabled, className } = props;

  const checkboxClasses = classNames(className, {
    checkbox: true,
    "checkbox--disabled": disabled,
  });
  const descriptionClasses = classNames({
    label: true,
    checkbox__description: true,
    "checkbox--error": error,
  });

  return (
    <fieldset className={checkboxClasses} id={id} aria-required={required}>
      {description && (
        <legend className={descriptionClasses}>
          {description} {required && <span className="required">*</span>}
        </legend>
      )}
      <SharedCheckbox {...props} />
    </fieldset>
  );
};

export const Checkbox = (props: CheckboxProps) => {
  const { id, required = false, disabled, className } = props;

  const checkboxClasses = classNames(className, {
    checkbox: true,
    "checkbox--disabled": disabled,
  });

  return (
    <div className={checkboxClasses} id={id}>
      <SharedCheckbox aria-required={required} {...props} />
    </div>
  );
};
