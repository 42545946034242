import { defaultIconProps, IconSvgWrapper, type IconProps } from "./base";

const TrashIcon = (props: IconProps) => {
  props = { ...defaultIconProps, ...props };

  return (
    <IconSvgWrapper {...props}>
      <path
        className={`icon--${props.color}`}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.924 4.32C20.172 4.32 21.216 5.304 21.228 6.552C21.24 7.56 20.58 8.412 19.68 8.712V20.52C19.68 21.768 18.672 22.776 17.424 22.776H6.57601C5.32801 22.776 4.32001 21.768 4.32001 20.52V8.712C3.42001 8.412 2.76001 7.56 2.77201 6.552C2.78401 5.304 3.82801 4.32 5.07601 4.32H8.08801C8.18401 4.32 8.28001 4.26 8.30401 4.152C8.70001 2.472 10.2 1.224 12 1.224C13.788 1.224 15.3 2.472 15.696 4.152C15.72 4.248 15.816 4.32 15.912 4.32H18.924ZM14.172 4.008C13.788 3.192 12.96 2.628 12 2.628C11.04 2.628 10.212 3.192 9.82801 4.008C9.75601 4.152 9.85201 4.32 10.02 4.32H13.98C14.136 4.32 14.244 4.152 14.172 4.008ZM17.424 21.372C17.892 21.372 18.276 20.988 18.276 20.52V8.82H5.72401V20.52C5.72401 20.988 6.10801 21.372 6.57601 21.372H17.424ZM5.02801 7.428H18.972C19.44 7.428 19.824 7.044 19.836 6.576C19.836 6.108 19.452 5.724 18.984 5.724H15.804H8.20801H5.02801C4.56001 5.724 4.17601 6.108 4.17601 6.576C4.17601 7.044 4.56001 7.428 5.02801 7.428ZM12.696 18.2759C12.696 18.6599 12.384 18.9719 12 18.9719C11.616 18.9719 11.304 18.6599 11.304 18.2759V11.9279C11.304 11.5439 11.616 11.2319 12 11.2319C12.384 11.2319 12.696 11.5439 12.696 11.9279V18.2759ZM15.096 18.9719C15.48 18.9719 15.792 18.6599 15.792 18.2759V11.9279C15.792 11.5439 15.48 11.2319 15.096 11.2319C14.712 11.2319 14.4 11.5439 14.4 11.9279V18.2759C14.4 18.6599 14.712 18.9719 15.096 18.9719ZM9.60002 18.2759C9.60002 18.6599 9.28802 18.9719 8.90402 18.9719C8.50802 18.9719 8.19602 18.6599 8.20802 18.2759V11.9279C8.20802 11.5439 8.52002 11.2319 8.90402 11.2319C9.28802 11.2319 9.60002 11.5439 9.60002 11.9279V18.2759Z"
      />
    </IconSvgWrapper>
  );
};

export default TrashIcon;
