import { type Badges } from "~/utils/data_warehouse/client";

interface Result {
  badges: Badges | null;
  success: boolean;
}

export const fetchBadges = async (hiringPlanId: string | number): Promise<Result> => {
  try {
    const url = `/badges/${hiringPlanId}`;

    const response = await fetch(url);

    if (!response.ok) {
      return { badges: null, success: false };
    }

    const badges = await response.json();

    return { badges, success: true };
  } catch (error) {
    return { badges: null, success: false };
  }
};
