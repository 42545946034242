import buildScript from "~/utils/build_script";

const waitForWindowKey = (key: string, retries: number, count: number, callback: () => void) => {
  if ((window as any)[key]) {
    callback();
  } else {
    const delayInMs = 30;
    const maxRetry = 7;

    if (retries > maxRetry) {
      console.error("Failed to get window key for Google Drive. Key: " + key);
      return;
    }

    retries += 1;
    count += 1;

    setTimeout(function () {
      waitForWindowKey(key, retries, count, callback);
    }, delayInMs * count);
  }
};

export const loadGooglePicker = () => {
  let retries = 0;
  let count = 1;

  waitForWindowKey("gapiLoaded", retries, count, () =>
    buildScript("https://apis.google.com/js/api.js", {}, window.gapiLoaded)
  );
  waitForWindowKey("gisLoaded", retries, count, () =>
    buildScript("https://accounts.google.com/gsi/client", {}, window.gisLoaded)
  );
};
