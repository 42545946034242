import type { BoardConfiguration } from "~/types/board_configuration";

const BASE_LINK = "https://fonts.googleapis.com/css2?";
const STYLES = ":ital,wght@0,400;0,500;0,700;1,400;1,500;1,700";
const STANDARD_FONTS = [
  "Arial",
  "Courier New",
  "Garamond",
  "Georgia",
  "Helvetica",
  "Palatino",
  "Times",
  "Times New Roman",
  "Untitled Sans",
  "Verdana",
  "untitled_sans",
  "arial",
  "helvetica",
  "times_new_roman",
  "verdana",
];

function buildGoogleFontsLink(fonts: (string | null | undefined)[]) {
  const googleFontsLinks = fonts
    .filter((font) => !!font)
    .filter((font) => !STANDARD_FONTS.includes(font as string))
    .map((font) => `family=${font?.replace(/ /g, "+")}${STYLES}`)
    .join("&");

  if (googleFontsLinks.length === 0) {
    return null;
  }

  return `${BASE_LINK}${googleFontsLinks}`;
}

export function buildGoogleFontsLinks(config: BoardConfiguration | null, preview = false) {
  const links: (string | null)[] = [];

  // If we're previewing, we want to load the fonts separately to avoid flashes of unstyled content as fonts are changed in Design Studio
  if (preview) {
    links.push(buildGoogleFontsLink([config?.primary_font]));
    links.push(buildGoogleFontsLink([config?.secondary_font]));
    links.push(buildGoogleFontsLink([config?.primary_font_fallback]));
    links.push(buildGoogleFontsLink([config?.secondary_font_fallback]));
  } else {
    links.push(buildGoogleFontsLink([config?.primary_font, config?.secondary_font]));
  }

  return links.filter((link) => !!link);
}
