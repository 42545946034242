import { useState, useEffect } from "react";
import "./quick_apply_flash.scss";
import { Body, Link } from "../components/typography";
import CheckIcon from "../components/icons/check";
import ErrorIcon from "../components/icons/error";
import { useTranslation } from "react-i18next";
import { Flash } from "~/components/flash";

type QuickApplyFlashProps = {
  type: "success" | "error";
  closeFlash: () => void;
  embedded?: boolean;
};

export const QuickApplyFlash = ({ type, closeFlash, embedded }: QuickApplyFlashProps) => {
  const { t } = useTranslation("job_post");

  const [shouldRender, setShouldRender] = useState(false);
  const [jobSeekersUrl, setJobSeekersUrl] = useState("");

  useEffect(() => {
    setJobSeekersUrl(`${window.ENV.JOB_SEEKERS_URL}/profile`);
  }, []);

  useEffect(() => {
    setShouldRender(true);
  }, [setShouldRender]);

  if (!shouldRender) return null;

  let icon = <CheckIcon color="green" />;
  let message = (
    <Body>
      {t("application.quick_apply.success")}{" "}
      {
        <Link href={jobSeekersUrl} target="_blank">
          MyGreenhouse
        </Link>
      }
    </Body>
  );

  if (type === "error") {
    icon = <ErrorIcon color="red" />;
    message = <Body>{t("application.quick_apply.error")}</Body>;
  }

  const mountElement = window.document.getElementById("react-portal-mount-point");

  if (embedded) {
    window.parent.postMessage({ type: "autofill", editProfileLink: jobSeekersUrl }, "*");
    return null;
  }

  if (!mountElement) return null;

  return (
    <Flash
      icon={icon}
      backgroundColor={type === "error" ? "extra-light-red" : "paler-gray"}
      message={message}
      showCloseIcon={type !== "error"}
      closeFlash={closeFlash}
    />
  );
};
