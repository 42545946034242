import type { FileUploadData } from "~/components/file_upload";
import FileUpload from "~/components/file_upload";
import { type UploadedFile } from "~/utils/uploads/uploaded_file";
import { TextInput } from "~/components/text_input";
import { type OnSelectParam, type Option, Select } from "~/components/select";
import { TextArea } from "~/components/text_area";
import { MultiCheckbox } from "~/components/checkbox";
import type UploadManager from "~/utils/uploads/upload_manager";
import { useBoardConfiguration } from "~/hooks/use_board_configuration";
import LocationSelect from "~/components/location_select";
import { useLocation } from "@remix-run/react";

import { PeliasResult, MapboxResult, type LocationControlResult } from "@grnhse/location-control";
import { isEmbeddedRoute } from "~/utils/route_helpers";

export type FieldValue = string | string[] | UploadedFile | null;

export interface FieldProps {
  label: string;
  name: string;
  required: boolean;
  type:
    | "input_text"
    | "textarea"
    | "input_file"
    | "multi_value_single_select"
    | "multi_value_multi_select";
  options: Option[];
  allowManual?: boolean;
  error?: string;
  internal?: boolean;
  onChange: (value: FieldValue) => void;
  uploadManager?: UploadManager;
  multiSelectStyle?: string;
  value?: FieldValue;
  allowedFiletypes?: string[];
  location?: string | null;
  onFocus?: () => void;
  selected?: OnSelectParam;
}

const autoCompleteValues: Record<string, string> = {
  first_name: "given-name",
  last_name: "family-name",
  phone: "tel",
};

export const Field = ({
  label,
  name,
  required,
  type,
  options,
  allowManual,
  error,
  internal,
  onChange,
  uploadManager,
  multiSelectStyle,
  value,
  allowedFiletypes,
  location,
  onFocus,
  selected,
}: FieldProps) => {
  const { outside_label, google_drive_enabled, enable_dropbox } = useBoardConfiguration();

  const { pathname } = useLocation();

  const handleChange = (
    value: string | Option | Readonly<Option[]> | null | LocationControlResult
  ) => {
    if (!value) {
      return onChange("");
    }

    if (
      typeof value === "string" ||
      value instanceof MapboxResult ||
      value instanceof PeliasResult
    ) {
      // @ts-ignore - Issue around this method being overloaded
      return onChange(value);
    }

    if (value instanceof Array) {
      return onChange(value.map((option) => option.value.toString()));
    }

    onChange(value.value.toString());
  };

  const handleFileUploadChange = (data: FileUploadData) => {
    if (data.file) {
      onChange(data.file);
    } else if (data.text) {
      onChange(data.text);
    } else {
      onChange(null);
    }
  };

  const isCheckbox = multiSelectStyle === "checkbox";

  switch (type) {
    case "input_text":
      if (name === "candidate_location") {
        return (
          <LocationSelect
            handleChange={handleChange}
            error={error}
            required={required}
            outsideLabel={outside_label}
            location={location}
            onFocus={onFocus}
          />
        );
      }

      return (
        <TextInput
          id={name}
          label={label}
          required={required}
          onChange={(event) => handleChange(event.currentTarget.value)}
          error={error}
          maxLength={255}
          outsideLabel={outside_label}
          value={(value as string) || ""}
          autoComplete={autoCompleteValues[name]}
          onFocus={onFocus}
        />
      );
    case "textarea":
      return (
        <TextArea
          id={name}
          label={label}
          required={required}
          onChange={(event) => handleChange(event.currentTarget.value)}
          error={error}
          outsideLabel={outside_label}
          onFocus={onFocus}
        />
      );
    case "input_file":
      const manualInputId = allowManual ? `${name}_text` : undefined;

      if (!uploadManager) {
        console.error("UploadManager not provided to Field");
        return null;
      }

      return (
        <FileUpload
          id={name}
          manualInputId={manualInputId}
          label={label}
          onChange={handleFileUploadChange}
          loadDropbox={enable_dropbox}
          allowManual={allowManual}
          errorMessage={error}
          internal={internal}
          uploadManager={uploadManager}
          required={required}
          loadGoogleDrive={google_drive_enabled && !isEmbeddedRoute(pathname)}
          filetypes={allowedFiletypes}
          fileName={(value as UploadedFile)?.name}
        />
      );
    case "multi_value_multi_select":
      return isCheckbox ? (
        <MultiCheckbox
          id={name}
          options={options}
          description={label}
          required={required}
          error={error}
          isMulti
          onSelect={handleChange}
          internal={internal}
        />
      ) : (
        <Select
          id={name}
          label={label}
          required={required}
          options={options}
          onSelect={(selected) => handleChange(selected)}
          isMulti={true}
          error={error}
          isClearable
          outsideLabel={outside_label}
        />
      );
    case "multi_value_single_select":
      return (
        <Select
          id={name}
          label={label}
          required={required}
          options={options}
          onSelect={(selected) => handleChange(selected)}
          error={error}
          isClearable
          outsideLabel={outside_label}
          selected={selected}
        />
      );
    default:
      return <></>;
  }
};
