export const isPreviewRoute = (url: string) => /\/preview\/(board|job)(\/*)?/.test(url);

export const isNewPreviewRoute = (url: string) => /\/live_preview\/(board|job)(\/*)?/.test(url);

export const isInternalRoute = (url: string) =>
  /\/internal_job_board(\/*)?/.test(url) || isPreviewRoute(url);

export const isExternalJobPostRoute = (urlString: string): boolean => {
  const url = new URL(urlString);

  return /^\/.+\/jobs\/\d+$/.test(url.pathname);
};

export const isInternalJobPostRoute = (urlString: string) => {
  const url = new URL(urlString);

  if (!url.searchParams.has("token")) {
    return false;
  }

  return /^\/internal_job_board\/applications\/\d+$/.test(url.pathname);
};

export const isEmbeddedRoute = (url: string) => {
  return /\/embed\/(job_app|job_board)/.test(url);
};

export const isEmbeddedJobPostRoute = (url: string) => {
  return /\/embed\/job_app(?!\/confirmation)/.test(url);
};

export const isJobPostRoute = (url: string) =>
  isExternalJobPostRoute(url) || isInternalJobPostRoute(url) || isEmbeddedJobPostRoute(url);
