import * as React from "react";
import "./animations.scss";

const FadeInAnimation = (props: { onAnimationEnd: () => void; children: React.ReactNode }) => {
  React.useEffect(() => {
    setTimeout(() => props.onAnimationEnd(), 1000);
  });

  return <div className="fade-in">{props.children}</div>;
};

export default FadeInAnimation;
