import buildScript from "~/utils/build_script";
import { isServer } from "~/utils/env_check";
import { AppcastEvent } from "~/hooks/use_appcast";

export const loadAppcast = async (
  enableAppcast: boolean,
  appcastEvent: AppcastEvent,
  appcastTrackingCode?: string | null
) => {
  if (isServer() || !enableAppcast) return;

  if (!appcastTrackingCode) {
    console.error("Tried to load Appcast but no tracking code was provided. Skipping...");
    return;
  }

  let scriptUrl;
  if (appcastEvent === AppcastEvent.JobView) {
    scriptUrl = `https://click.appcast.io/pixels/greenhouse1-${appcastTrackingCode}.js?t=${Date.now()}`;
  } else if (appcastEvent === AppcastEvent.ApplyComplete) {
    scriptUrl = `https://click.appcast.io/pixels/greenhouse3-${appcastTrackingCode}.js?t=${Date.now()}`;
  } else {
    console.error("Tried to load Appcast with an invalid event. Skipping...");
    return;
  }

  try {
    await buildScript(scriptUrl);
  } catch {
    console.error("Failed to load Appcast");
  }
};
