import { scrollToElement } from "~/utils/scroll_to_element";

const focusLastSection = (sectionSelector: string) => {
  const sections = document.querySelectorAll(sectionSelector);

  if (sections?.length > 0) {
    scrollToElement(sections[sections.length - 1] as HTMLElement);

    const input = sections[sections.length - 1].querySelector("input");

    if (input) {
      input.focus();
    }
  }
};

export { focusLastSection };
