import "./employment_section.scss";
import { Body } from "../typography";
import { TextInput } from "../text_input";
import { useBoardConfiguration } from "~/hooks/use_board_configuration";
import { useUpdateEffect } from "react-use";
import { Select, type Option } from "../select";
import { Checkbox } from "../checkbox";
import TrashIcon from "../icons/trash";
import { IconButton } from "../icon_button";
import type { EmploymentAnswers } from "~/utils/build_application";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { focusLastSection } from "~/utils/focus_last_section";
import { Resizer } from "~/utils/resizer";
import { useMonthOptions } from "~/hooks/use_month_options";

interface Props {
  employments: EmploymentAnswers;
  required: boolean;
  internal?: boolean;
  errors: Record<string, string>[];
  onChange: (employments: EmploymentAnswers) => void;
  onFocus?: () => void;
}

export const EmploymentSection = ({
  employments,
  required,
  internal,
  errors,
  onChange,
  onFocus,
}: Props) => {
  const { outside_label } = useBoardConfiguration();
  const { t } = useTranslation("job_post");

  const [shouldFocusSection, setShouldFocusSection] = useState<boolean>(false);

  const months = useMonthOptions();

  useUpdateEffect(() => {
    Resizer.getInstance().handleResize();
  }, [employments.length]);

  useEffect(() => {
    if (shouldFocusSection) {
      focusLastSection(".employment-form");
      setShouldFocusSection(false);
    }
  }, [shouldFocusSection]);

  const updateEmploymentValue = (
    index: number,
    key:
      | "companyName"
      | "title"
      | "startDateMonth"
      | "startDateYear"
      | "endDateMonth"
      | "endDateYear"
      | "currentRole",
    value: any
  ) => {
    const newEmployments = [...employments];
    newEmployments[index][key] = value;
    onChange(newEmployments);
  };

  const handleFocus = () => {
    if (shouldFocusSection) {
      return;
    }

    onFocus?.();
  };

  const fields = () => {
    return employments.map((employment, index) => {
      const error = errors[index];
      const selectedStartMonth = months.find((m) => m.value === employment.startDateMonth);
      const selectedEndMonth = months.find((m) => m.value === employment.endDateMonth);

      const idFor = (key: string) => `${key}-${employment.key}`;

      return (
        <div key={employment.key} className="employment-form">
          <hr />
          <div className="header-row">
            <Body medium>{t("employment.employment")}</Body>
            {employments.length > 1 && (
              <IconButton
                label="Remove employment"
                icon={TrashIcon}
                onClick={() => {
                  onChange(employments.filter((e) => e.key !== employment.key));
                }}
              ></IconButton>
            )}
          </div>
          <TextInput
            id={idFor("company-name")}
            required={required}
            label={t("employment.company_name")}
            maxLength={255}
            onChange={(event) => {
              updateEmploymentValue(index, "companyName", event.currentTarget.value);
            }}
            outsideLabel={outside_label}
            error={error?.company_name}
            value={employment.companyName}
            onFocus={handleFocus}
          />
          <TextInput
            id={idFor("title")}
            required={required}
            label={t("employment.title")}
            maxLength={255}
            onChange={(event) => {
              updateEmploymentValue(index, "title", event.currentTarget.value);
            }}
            outsideLabel={outside_label}
            error={error?.title}
            value={employment.title}
            onFocus={handleFocus}
          />
          <div className="date-row">
            <Select
              id={idFor("start-date-month")}
              label={t("employment.start_date_month")}
              required={required}
              options={months}
              onSelect={(option) => {
                updateEmploymentValue(index, "startDateMonth", (option as Option)?.value);
              }}
              isClearable
              outsideLabel={outside_label}
              error={error?.start_date_month}
              selected={selectedStartMonth}
              onFocus={handleFocus}
            />
            <TextInput
              id={idFor("start-date-year")}
              required={required}
              label={t("employment.start_date_year")}
              maxLength={4}
              onChange={(event) => {
                updateEmploymentValue(index, "startDateYear", event.currentTarget.value);
              }}
              outsideLabel={outside_label}
              error={error?.start_date_year}
              value={employment.startDateYear?.toString()}
              onFocus={handleFocus}
            />
          </div>
          <div className="date-row">
            <Select
              id={idFor("end-date-month")}
              label={t("employment.end_date_month")}
              required={required}
              options={months}
              onSelect={(option) => {
                updateEmploymentValue(index, "endDateMonth", (option as Option)?.value);
              }}
              isClearable
              outsideLabel={outside_label}
              isDisabled={employment.currentRole}
              error={error?.end_date_month}
              selected={selectedEndMonth}
              onFocus={handleFocus}
            />
            <TextInput
              id={idFor("end-date-year")}
              required={required}
              label={t("employment.end_date_year")}
              maxLength={4}
              onChange={(event) => {
                updateEmploymentValue(index, "endDateYear", event.currentTarget.value);
              }}
              outsideLabel={outside_label}
              disabled={employment.currentRole}
              error={error?.end_date_year}
              value={employment.endDateYear?.toString()}
              onFocus={handleFocus}
            />
            <Checkbox
              isMulti
              internal={internal}
              id={idFor("current-role")}
              options={[
                {
                  value: "1",
                  label: t("employment.current_role"),
                },
              ]}
              onSelect={(selected) => {
                updateEmploymentValue(index, "currentRole", (selected as Option[]).length > 0);
              }}
              values={employment.currentRole ? ["1"] : []}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <div className="employment--container">
      {fields()}

      <button
        className="add-another-button"
        type="button"
        onClick={() => {
          const lastKey = employments[employments.length - 1].key as number;
          onChange([...employments, { key: lastKey + 1 }]);
          setShouldFocusSection(true);
        }}
      >
        {t("employment.add_another")}
      </button>
    </div>
  );
};
