import buildScript from "~/utils/build_script";
import { isServer } from "~/utils/env_check";

class Recaptcha {
  private readonly invisibleKey: string;
  private readonly endpoint: string;

  constructor() {
    if (isServer()) throw new Error("Recaptcha only works in the browser.");

    this.invisibleKey = window.ENV.GOOGLE_RECAPTCHA_INVISIBLE_KEY;
    this.endpoint = window.ENV.GOOGLE_RECAPTCHA_ENDPOINT;
  }

  init() {
    if (!window.grecaptcha) {
      void buildScript(`${this.endpoint}?render=${this.invisibleKey}`);
    }
  }

  invisibleCaptcha() {
    return window.grecaptcha.enterprise.execute(this.invisibleKey, {
      action: "apply_to_job",
    });
  }

  async performAssessment() {
    try {
      const assessmentToken = await this.invisibleCaptcha();

      return assessmentToken;
    } catch (e) {
      console.error(e);
      return "";
    }
  }
}

export default Recaptcha;
