import "./education_section.scss";
import { Body } from "../typography";
import {
  type EducationConfiguration,
  type EducationConfigurationOption,
} from "~/types/jben/job_post";
import { IconButton } from "../icon_button";
import TrashIcon from "../icons/trash";
import { useEffect, useState } from "react";
import { useUpdateEffect } from "react-use";
import { type Option, Select } from "../select";
import { useUrlToken } from "~/hooks/use_url_token";
import { TextInput } from "../text_input";
import { type EducationErrors } from "~/utils/validate_application";
import { useTranslation } from "react-i18next";
import { fetchEducation } from "~/utils/fetch_education";
import { useBoardConfiguration } from "~/hooks/use_board_configuration";
import { focusLastSection } from "~/utils/focus_last_section";
import { Resizer } from "~/utils/resizer";
import { useMonthOptions } from "~/hooks/use_month_options";

export interface EducationAnswer {
  key: number;
  school?: Option;
  degree?: Option;
  discipline?: Option;
  start_month?: Option;
  start_year?: number;
  end_month?: Option;
  end_year?: number;
}

interface Props {
  answers: EducationAnswer[];
  errors: Record<number, EducationErrors>;
  educationConfiguration: EducationConfiguration;
  onChange: (educationAnswers: EducationAnswer[]) => void;
  onFocus?: () => void;
}

function isOptional(educationConfigurationOption: EducationConfigurationOption): boolean {
  return educationConfigurationOption === "optional";
}

function isRequired(educationConfigurationOption: EducationConfigurationOption): boolean {
  return educationConfigurationOption === "required";
}

function shouldDisplay(educationConfigurationOption: EducationConfigurationOption): boolean {
  return isOptional(educationConfigurationOption) || isRequired(educationConfigurationOption);
}

export const EducationSection = ({
  educationConfiguration,
  onChange,
  answers,
  errors,
  onFocus,
}: Props) => {
  const urlToken = useUrlToken();
  const { outside_label } = useBoardConfiguration();
  const { t } = useTranslation("job_post");

  const monthOptions = useMonthOptions();

  const [degreeOptions, setDegreeOptions] = useState<Option[]>([]);
  const [disciplineOptions, setDisciplineOptions] = useState<Option[]>([]);
  const [schoolOptions, setSchoolOptions] = useState<Option[]>([]);

  const [degreePage, setDegreePage] = useState<number>(1);
  const [disciplinePage, setDisciplinePage] = useState<number>(1);
  const [schoolPage, setSchoolPage] = useState<number>(1);

  const [degreeSearch, setDegreeSearch] = useState<string>("");
  const [disciplineSearch, setDisciplineSearch] = useState<string>("");
  const [schoolSearch, setSchoolSearch] = useState<string>("");

  const [shouldFocusSection, setShouldFocusSection] = useState<boolean>(false);

  const displayStartMonth = shouldDisplay(educationConfiguration.start_month);
  const displayStartYear = shouldDisplay(educationConfiguration.start_year);

  const displayEndMonth = shouldDisplay(educationConfiguration.end_month);
  const displayEndYear = shouldDisplay(educationConfiguration.end_year);

  async function loadAsyncOptions(
    search: string,
    page: number,
    options: Option[],
    key: "schools" | "degrees" | "disciplines",
    setOptions: (options: Option[]) => void
  ) {
    const result = await fetchEducation({
      type: key,
      urlToken,
      term: search,
      page,
    });

    if (result.status !== 200) {
      return;
    }

    const newItems = result[key] || [];
    const records: Record<number, string> = {};

    options.forEach((option) => {
      records[option.value as number] = option.label;
    });

    newItems.forEach((record) => {
      records[record.id] = record.text;
    });

    const newOptions = Object.entries(records).map(([value, label]) => ({
      value: parseInt(value),
      label,
    }));

    newOptions.sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }

      return 1;
    });

    setOptions(newOptions);
  }

  useEffect(() => {
    loadAsyncOptions(degreeSearch, degreePage, degreeOptions, "degrees", setDegreeOptions).catch(
      console.error
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [degreePage, degreeSearch]);

  useEffect(() => {
    loadAsyncOptions(
      disciplineSearch,
      disciplinePage,
      disciplineOptions,
      "disciplines",
      setDisciplineOptions
    ).catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disciplinePage, disciplineSearch]);

  useEffect(() => {
    loadAsyncOptions(schoolSearch, schoolPage, schoolOptions, "schools", setSchoolOptions).catch(
      console.error
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolPage, schoolSearch]);

  useEffect(() => {
    if (shouldFocusSection) {
      focusLastSection(".education--form");
      setShouldFocusSection(false);
    }
  }, [shouldFocusSection]);

  useUpdateEffect(() => {
    Resizer.getInstance().handleResize();
  }, [answers.length]);

  const updateEducationValue = (
    index: number,
    key: keyof EducationAnswer,
    value: Option | string
  ) => {
    const newAnswers = [...answers];
    newAnswers[index] = {
      ...newAnswers[index],
      [key]: value,
    };

    onChange(newAnswers);
  };

  const handleFocus = () => {
    if (shouldFocusSection) {
      return;
    }

    onFocus?.();
  };

  const fields = () => {
    return answers.map((answer, index) => {
      const error = errors[index];

      return (
        <div key={answer.key.toString()} className="education--form">
          <hr />
          <div className="education--header">
            <Body medium>{t("education.title")}</Body>
            {answers.length > 1 && (
              <IconButton
                label="Remove education"
                icon={TrashIcon}
                onClick={() => {
                  onChange(answers.filter((e) => e.key !== answer.key));
                }}
              />
            )}
          </div>
          {shouldDisplay(educationConfiguration.school_name) && (
            <Select
              id={`school--${answer.key}`}
              label={t("education.labels.school")}
              options={schoolOptions}
              onSelect={(selected) => updateEducationValue(index, "school", selected as Option)}
              selected={answer.school}
              isClearable={true}
              onMenuScrollToBottom={() => setSchoolPage(schoolPage + 1)}
              onInputChange={(value) => setSchoolSearch(value)}
              required={isRequired(educationConfiguration.school_name)}
              outsideLabel={outside_label}
              error={error?.school}
              onFocus={handleFocus}
            />
          )}
          {shouldDisplay(educationConfiguration.degree) && (
            <Select
              id={`degree--${answer.key}`}
              label={t("education.labels.degree")}
              options={degreeOptions}
              onSelect={(selected) => updateEducationValue(index, "degree", selected as Option)}
              selected={answer.degree}
              isClearable={true}
              onMenuScrollToBottom={() => setDegreePage(degreePage + 1)}
              onInputChange={(value) => setDegreeSearch(value)}
              required={isRequired(educationConfiguration.degree)}
              outsideLabel={outside_label}
              error={error?.degree}
              onFocus={handleFocus}
            />
          )}
          {shouldDisplay(educationConfiguration.discipline) && (
            <Select
              id={`discipline--${answer.key}`}
              label={t("education.labels.discipline")}
              options={disciplineOptions}
              onSelect={(selected) => updateEducationValue(index, "discipline", selected as Option)}
              selected={answer.discipline}
              isClearable={true}
              onMenuScrollToBottom={() => setDisciplinePage(disciplinePage + 1)}
              onInputChange={(value) => setDisciplineSearch(value)}
              required={isRequired(educationConfiguration.discipline)}
              outsideLabel={outside_label}
              error={error?.discipline}
              onFocus={handleFocus}
            />
          )}
          {(displayStartMonth || displayStartYear || displayEndMonth || displayEndYear) && (
            <div className="education--half-width-container">
              {(displayStartMonth || displayStartYear) && (
                <div className="education--date-container">
                  {displayStartMonth && (
                    <Select
                      id={`start-month--${answer.key}`}
                      label={t("education.labels.start_month")}
                      options={monthOptions}
                      onSelect={(selected) =>
                        updateEducationValue(index, "start_month", selected as Option)
                      }
                      selected={answer.start_month}
                      isClearable={true}
                      required={isRequired(educationConfiguration.start_month)}
                      outsideLabel={outside_label}
                      error={error?.start_month}
                      onFocus={handleFocus}
                    />
                  )}
                  {displayStartYear && (
                    <TextInput
                      id={`start-year--${answer.key}`}
                      label={t("education.labels.start_year")}
                      type="number"
                      value={answer.start_year?.toString()}
                      onChange={(event) =>
                        updateEducationValue(index, "start_year", event.currentTarget.value)
                      }
                      required={isRequired(educationConfiguration.start_year)}
                      outsideLabel={outside_label}
                      error={error?.start_year}
                      onFocus={handleFocus}
                    />
                  )}
                </div>
              )}
              {(displayEndMonth || displayEndYear) && (
                <div className="education--date-container">
                  {displayEndMonth && (
                    <Select
                      id={`end-month--${answer.key}`}
                      label={t("education.labels.end_month")}
                      options={monthOptions}
                      onSelect={(selected) =>
                        updateEducationValue(index, "end_month", selected as Option)
                      }
                      selected={answer.end_month}
                      isClearable={true}
                      required={isRequired(educationConfiguration.end_month)}
                      outsideLabel={outside_label}
                      error={error?.end_month}
                      onFocus={handleFocus}
                    />
                  )}
                  {displayEndYear && (
                    <TextInput
                      id={`end-year--${answer.key}`}
                      label={t("education.labels.end_year")}
                      type="number"
                      value={answer.end_year?.toString()}
                      onChange={(event) =>
                        updateEducationValue(index, "end_year", event.currentTarget.value)
                      }
                      required={isRequired(educationConfiguration.end_year)}
                      outsideLabel={outside_label}
                      error={error?.end_year}
                      onFocus={handleFocus}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <div className="education--container">
      {fields()}
      <button
        className="add-another-button"
        type="button"
        onClick={() => {
          const lastKey = answers[answers.length - 1].key as number;
          onChange([...answers, { key: lastKey + 1 }]);
          setShouldFocusSection(true);
        }}
      >
        {t("education.add_another")}
      </button>
    </div>
  );
};
