import { useEffect, useState } from "react";
import loadDropboxChooser from "~/utils/load_dropbox_chooser";

export const useDropboxChooser = (enableDropbox: boolean) => {
  const [dropboxChooserOpen, setDropboxChooserOpen] = useState(false);

  useEffect(() => {
    if (enableDropbox) {
      void loadDropboxChooser();
    }
  }, [enableDropbox]);

  const loaded = () => !!window.Dropbox;

  const browserSupported = () => window.Dropbox?.isBrowserSupported();

  const openDropboxChooser = (
    fileTypes: Array<string>,
    onSuccess: (files: readonly Dropbox.ChooserFile[]) => Promise<void>,
    onCancel?: () => void
  ) => {
    if (!enableDropbox || !loaded() || dropboxChooserOpen) return;

    window.Dropbox?.choose({
      linkType: "direct",
      multiselect: false,
      extensions: fileTypes,
      success: (files) => {
        setDropboxChooserOpen(false);
        void onSuccess(files);
      },
      cancel: () => {
        setDropboxChooserOpen(false);
        onCancel?.();
      },
    });

    // This prevents multiple Dropbox choosers from opening if the user spams the Dropbox button.
    // If multiple choosers are open when the user selects a file, they'll all resolve at once
    // causing multiple uploads in quick succession.
    setDropboxChooserOpen(true);
  };

  return { openDropboxChooser, dropbox: { loaded, browserSupported } };
};
