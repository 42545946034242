import classNames from "classnames";
import "./logo.scss";
import { useTranslation } from "react-i18next";

interface Props {
  companyName: string;
  url: string | null;
  href: string | null;
}

const ImageContainer = ({ children }: { children: React.ReactNode }) => {
  return <div className="image-container">{children}</div>;
};

export const Logo = ({ companyName, url, href }: Props) => {
  const { t } = useTranslation("common");

  if (!url) {
    return null;
  }

  const wrapWithLink = !!href;

  const altText = `${companyName} ${t("labels.logo")}`;

  const imageElementClasses = classNames({
    logo: !wrapWithLink,
  });

  const linkElementClasses = classNames({
    logo: wrapWithLink,
  });

  const imageElement = <img src={url} alt={altText} className={imageElementClasses}></img>;

  if (wrapWithLink) {
    return (
      <ImageContainer>
        <a href={href} target="_blank" rel="noreferrer" className={linkElementClasses}>
          {imageElement}
        </a>
      </ImageContainer>
    );
  }

  return <ImageContainer>{imageElement}</ImageContainer>;
};
