import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useMonthOptions = () => {
  const { t } = useTranslation("job_post");

  return useMemo(
    () => [
      { label: t("months.january"), value: 1 },
      { label: t("months.february"), value: 2 },
      { label: t("months.march"), value: 3 },
      { label: t("months.april"), value: 4 },
      { label: t("months.may"), value: 5 },
      { label: t("months.june"), value: 6 },
      { label: t("months.july"), value: 7 },
      { label: t("months.august"), value: 8 },
      { label: t("months.september"), value: 9 },
      { label: t("months.october"), value: 10 },
      { label: t("months.november"), value: 11 },
      { label: t("months.december"), value: 12 },
    ],
    [t]
  );
};
