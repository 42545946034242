import { useState, useMemo, useCallback } from "react";
import { Select } from "~/components/select";
import { isClient } from "~/utils/env_check";
import LocationControl from "@grnhse/location-control";
import type { LocationControlResult } from "@grnhse/location-control";
import type { OnSelectParam, Option } from "~/components/select";
import { useTranslation } from "react-i18next";
import { Button } from "~/components/button";

type Props = {
  handleChange: (result: LocationControlResult | null) => void;
  required?: boolean;
  outsideLabel?: boolean;
  location?: string | null;
  error?: string | null;
  onFocus?: () => void;
};

const SERVICE_UNAVAILABLE = "SERVICE UNAVAILABLE";
const ERROR_TOKEN_OPTION = {
  id: "",
  city: "",
  name: "",
  displayName: SERVICE_UNAVAILABLE,
  country_long_name: "",
  postal_code: "",
  state_long_name: "",
  state_short_name: "",
  country_short_name: "",
  latitude: 0,
  longitude: 0,
  label: "",
  value: SERVICE_UNAVAILABLE,
};

const LocationSelect = ({
  handleChange,
  required = false,
  outsideLabel = false,
  location,
  error = null,
  onFocus,
}: Props) => {
  const { t } = useTranslation("job_post");
  const [selectedOption, setSelectedOption] = useState<Readonly<Option | undefined>>();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [options, setOptions] = useState<Option[]>([]);

  const client = isClient();
  const locationControl = useMemo(() => {
    if (!client) {
      return null;
    }

    return new LocationControl({
      provider: window.ENV.LOCATION_CONTROL_PROVIDER,
      apiKey: window.ENV.LOCATION_CONTROL_API_KEY,
      baseUrl: window.ENV.LOCATION_CONTROL_BASE_URL,
    });
  }, [client]);

  const loadOptions = useCallback(
    async (inputValue: string): Promise<Option[]> => {
      if (!locationControl) return Promise.resolve([] as Option[]);

      const options = await locationControl.results(inputValue).catch(() => {
        setIsDisabled(true);
        handleChange(ERROR_TOKEN_OPTION);
        return [];
      });
      setOptions(options);
      return options;
    },
    [locationControl, handleChange]
  );

  const locateMe = () => {
    if (isLoading) return;

    setIsLoading(true);

    // ideally we'd catch and handle errors from locateMe like we do in loadOptions
    // however the location-control gem's error handling inside of locateMe doesn't provide an interface
    // it returns an error rather than calling the callback with it despite being async code
    locationControl?.locateMe((option: Option) => {
      handleChange(option as LocationControlResult);
      setSelectedOption(option);
      setIsLoading(false);
    });
  };

  const onSelect = async (selected: OnSelectParam) => {
    if (!locationControl) return;

    if (!selected) {
      handleChange(null);
      setSelectedOption(undefined);
      return;
    }

    const storableResult = await locationControl.resultSelected(selected as LocationControlResult);
    handleChange(storableResult);
    setSelectedOption(storableResult);
  };

  const currentValue = location ? { value: 0, label: location || "" } : selectedOption;

  return (
    <>
      <Select
        id="candidate-location"
        label={t("location.label")}
        onSelect={onSelect}
        isAsync={true}
        loadOptions={loadOptions}
        value={currentValue}
        isLoading={isLoading}
        isClearable={true}
        outsideLabel={outsideLabel}
        hideNoOptionsMessage={true}
        hideDropdownIndicator={true}
        defaultOptions={options}
        placeholderText=""
        required={required}
        error={error}
        onFocus={onFocus}
        isDisabled={isDisabled}
      />
      {!isDisabled && (
        <Button onClick={locateMe} tertiary>
          {t("location.locate_me")}
        </Button>
      )}
    </>
  );
};

export default LocationSelect;
