import { defaultIconProps, IconSvgWrapper, type IconProps } from "./base";

const ReminderIcon = (props: IconProps) => {
  props = { ...defaultIconProps, ...props };

  return (
    <IconSvgWrapper {...props}>
      <path
        className={`icon--${props.color}`}
        d="M18.732 14.0039C17.988 13.2359 17.34 12.5759 17.34 9.67191C17.34 7.47591 16.008 5.59191 14.112 4.76391C13.992 4.71591 13.944 4.58391 14.004 4.46391C14.172 4.12791 14.256 3.74391 14.232 3.33591C14.16 2.21991 13.26 1.30791 12.144 1.22391C10.848 1.13991 9.74401 2.18391 9.74401 3.47991C9.74401 3.83991 9.82801 4.17591 9.97201 4.47591C10.032 4.58391 9.97201 4.71591 9.86401 4.77591C7.96801 5.60391 6.63601 7.48791 6.63601 9.68391C6.63601 12.5399 6.00001 13.1999 5.25601 13.9799C4.45201 14.8199 3.54001 15.7679 3.54001 18.9839C3.54001 19.3679 3.85201 19.6799 4.23601 19.6799H8.25601C8.58001 21.4439 10.14 22.7759 11.988 22.7759C13.836 22.7759 15.396 21.4439 15.72 19.6799H19.74C20.124 19.6799 20.436 19.3679 20.436 18.9839C20.448 15.7679 19.536 14.8319 18.732 14.0039ZM11.148 3.47991C11.148 3.01191 11.532 2.62791 12 2.62791C12.468 2.62791 12.852 3.01191 12.852 3.47991C12.852 3.94791 12.48 4.31991 12.012 4.33191C12.012 4.33191 12.012 4.33191 12 4.33191H11.988C11.52 4.31991 11.148 3.94791 11.148 3.47991ZM12 21.3719C10.92 21.3719 10.008 20.6519 9.70801 19.6679H14.292C13.992 20.6639 13.08 21.3719 12 21.3719ZM5.19601 18.2759C5.06401 18.2759 4.96801 18.1679 4.98001 18.0359C5.11201 16.1519 5.66401 15.5879 6.27601 14.9399C7.10401 14.0759 8.05201 13.0919 8.05201 9.67191C8.05201 7.49991 9.81601 5.72391 11.988 5.72391H12H12.012C14.184 5.73591 15.948 7.49991 15.948 9.67191C15.948 13.1399 16.896 14.1239 17.736 14.9759C18.348 15.6119 18.888 16.1639 19.02 18.0359C19.032 18.1679 18.924 18.2759 18.804 18.2759H5.19601Z"
      />
    </IconSvgWrapper>
  );
};

export default ReminderIcon;
