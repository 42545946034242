import type { DemographicQuestion, JobPost } from "~/types/jben/job_post";
import type { DemographicQuestionAnswer } from "~/types/jben/application";
import type { DemographicAnswer } from "~/utils/build_application";

// support these 2 distinct Types that represent Answers to Demographic Questions
export const isDemographicDataConsentRequired = (
  jobPost: JobPost,
  demographicAnswers: DemographicQuestionAnswer[] | DemographicAnswer[][]
) => {
  const { data_compliance, demographic_questions } = jobPost;

  if (!data_compliance?.demographic_data_consent) return false;

  return (
    anyDemographicQuestionRequired(demographic_questions?.questions) ||
    anyDemographicQuestionAnswered(demographicAnswers)
  );
};

export const anyDemographicQuestionRequired = (demographicQuestions?: DemographicQuestion[]) => {
  if (!demographicQuestions) return false;

  return demographicQuestions.some((question) => question.required);
};

export const anyDemographicQuestionAnswered = (
  demographicAnswers: DemographicQuestionAnswer[] | DemographicAnswer[][]
) => {
  return demographicAnswers.some((answer) => {
    if ("answer_options" in answer) {
      return answer.answer_options.length > 0;
    } else {
      // ignore any freeform answers w/ empty values, as these will be filtered out by
      // buildApplication() when submitting the form
      const filteredAnswers = answer.filter((a) => a.freeform === undefined || a.freeform !== "");

      return filteredAnswers.length > 0;
    }
  });
};
