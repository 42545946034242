import serverLogger from "~/logger.server";
import { DajokuToken } from "~/greenhouse_oidc.server";
import RedisClient from "~/utils/redis_client.server";
import { serverOnly$ } from "vite-env-only/macros";
import { requestId as getRequestIdOrig } from "~/utils/request_scoped_storage";

const redisClient = serverOnly$(RedisClient.getInstance());
const logger = serverOnly$(serverLogger);
const buildDajokuToken = serverOnly$((audience: string) => new DajokuToken(audience));
const getRequestId = serverOnly$(getRequestIdOrig);

type FetchResourceParams = {
  url: string;
  key:
    | "job"
    | "jobs"
    | "board"
    | "departments"
    | "offices"
    | "boardConfiguration"
    | "eeoc_sections"
    | "schools"
    | "degrees"
    | "disciplines"
    | "custom_fields"
    | "featured_posts";
  internal?: boolean;
  cache?: boolean;
};

export const fetchResource = async ({
  key,
  url,
  cache = false,
  internal = false,
}: FetchResourceParams) => {
  const options: RequestInit = {};
  let response;

  if (cache && redisClient) {
    try {
      const cachedData = await redisClient.get(url);

      if (cachedData) {
        return {
          [key]: JSON.parse(cachedData),
          status: 200,
        };
      }
    } catch (e) {
      logger?.error(`Failed to get data from cache for ${url} with error: ${e}`);
    }
  }

  if (internal && buildDajokuToken) {
    try {
      const dajokuToken = buildDajokuToken("jben");
      options["headers"] = {
        authorization: `Bearer ${await dajokuToken.token()}`,
      };
    } catch (e) {
      logger?.error(`Failed to create dajoku token for ${url} with error: ${e}`);
      throw e;
    }
  }

  if (getRequestId) {
    options["headers"] = Object.assign({}, options["headers"], {
      "X-Orig-Request-Id": getRequestId(),
    });
  }

  try {
    response = await fetch(url, options);
  } catch (e) {
    logger?.error(`Failed to fetch ${url} with error: ${e}`);
    throw e;
  }

  const { status } = response;

  if (status >= 500) {
    const message = `Server error from ${url} - status: ${status}`;
    logger?.error(message);

    throw new Error(message);
  }

  const contentType = response.headers.get("content-type");

  let data;

  if (contentType && contentType.includes("application/json")) {
    try {
      data = await response.json();
    } catch (e) {
      logger?.error(`Failed to parse json response: ${e}`);
      throw e;
    }
  }

  if (cache && redisClient && status === 200 && data) {
    try {
      redisClient.set(url, JSON.stringify(data), "EX", 30);
    } catch (e) {
      logger?.error(`Failed to cache data for ${url} with error: ${e}`);
    }
  }

  return {
    [key]: data,
    status: response?.status,
  };
};
