import type { EeocSection } from "~/types/jben/job_post";
import EeocFormSection from "~/components/job_posts/eeoc/form_section";
import "./form.scss";
import type { FieldValue } from "../field";
import type { Option } from "~/components/select";

interface Props {
  answers: Record<string, FieldValue>;
  sections: EeocSection[];
  errors: Record<string, string>;
  onChange: (key: string, value: FieldValue) => void;
}

export interface EeocFormAnswers {
  disability_status?: Option;
  gender?: Option;
  race?: Option;
  veteran_status?: Option;
}

export const EeocForm = ({ answers, sections, errors, onChange }: Props) => {
  const eeocAnswers: EeocFormAnswers = {
    gender: sections[0].questions[0].fields[0].values.find(
      (val) => val.value === answers.gender?.toString()
    ),
    race: sections[0].questions[1].fields[0].values.find(
      (val) => val.value === answers.race?.toString()
    ),
    veteran_status: sections[1].questions[0].fields[0].values.find(
      (val) => val.value === answers.veteran_status?.toString()
    ),
    disability_status: sections[2].questions[0].fields[0].values.find(
      (val) => val.value === answers.disability_status?.toString()
    ),
  };

  return (
    <div className="eeoc__container body">
      <div className="divider" role="separator"></div>

      {sections.map((section, i) => (
        <EeocFormSection
          key={i}
          section={section}
          errors={errors}
          onChange={onChange}
          answers={eeocAnswers}
        />
      ))}
    </div>
  );
};
