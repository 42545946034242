import buildScript from "~/utils/build_script";

const loadDropboxChooser = async () => {
  try {
    buildScript("https://www.dropbox.com/static/api/2/dropins.js", {
      id: "dropboxjs",
      "data-app-key": window.ENV.DROPBOX_CHOOSER_API_KEY,
    });
  } catch {
    console.error("Failed to load Dropbox dropins script");
  }
};

export default loadDropboxChooser;
