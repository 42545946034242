import { defaultIconProps, IconSvgWrapper, type IconProps } from "./base";

const ArrowLeftIcon = (props: IconProps) => {
  props = { ...defaultIconProps, ...props };

  return (
    <IconSvgWrapper {...props}>
      <path
        className={`icon--${props.color}`}
        fill={props.color}
        d="M14.2728 3.13442C14.4151 3.13454 14.5542 3.17684 14.6725 3.25596C14.7908 3.33508 14.8829 3.44749 14.9374 3.57897C14.9918 3.71046 15.0061 3.85513 14.9784 3.99471C14.9506 4.13429 14.8822 4.26253 14.7816 4.36322L7.5696 11.5752C7.51372 11.631 7.46939 11.6972 7.43914 11.7701C7.40889 11.843 7.39332 11.9211 7.39332 12C7.39332 12.0789 7.40889 12.1571 7.43914 12.23C7.46939 12.3029 7.51372 12.3691 7.5696 12.4248L14.7816 19.6368C14.9088 19.7733 14.978 19.9538 14.9747 20.1404C14.9714 20.3269 14.8959 20.5049 14.7639 20.6368C14.632 20.7687 14.4541 20.8443 14.2675 20.8475C14.081 20.8508 13.9005 20.7816 13.764 20.6544L5.6208 12.5124C5.56703 12.4599 5.52206 12.3992 5.4876 12.3324C5.41742 12.1966 5.39227 12.0421 5.41579 11.8911C5.4393 11.74 5.51026 11.6004 5.6184 11.4924L13.764 3.34562C13.8989 3.21055 14.0819 3.13459 14.2728 3.13442Z"
      />
    </IconSvgWrapper>
  );
};

export default ArrowLeftIcon;
