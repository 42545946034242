import { useState, useEffect, type ReactNode } from "react";
import { createPortal } from "react-dom";
import classNames from "classnames";
import TimedFade from "./animations/timed_fade";
import ErrorIcon from "./icons/error";
import "./flash.scss";
import { Body } from "./typography";
import CheckIcon from "./icons/check";
import { LoadingSpinner } from "./loading_spinner";
import { IconButton } from "./icon_button";
import CloseIcon from "./icons/close";

type BaseFlashProps = {
  icon: ReactNode;
  backgroundColor: "extra-light-red" | "extra-light-green" | "white" | "paler-gray";
  message: string | ReactNode;
  showCloseIcon?: boolean;
  closeFlash?: () => void;
};

const TIME_VISIBLE_IN_MILLISECONDS = 12000;

export const Flash = (props: BaseFlashProps) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    setShouldRender(true);
  }, [setShouldRender]);

  if (!shouldRender) return null;

  const { icon, backgroundColor, message } = props;

  const flashContentsClassName = classNames({
    "flash-contents": true,
    [`flash-contents--${backgroundColor}`]: true,
  });

  const mountElement = window.document.getElementById("react-portal-mount-point");

  if (!mountElement) return null;

  return (
    <>
      {createPortal(
        <TimedFade showMilliseconds={TIME_VISIBLE_IN_MILLISECONDS}>
          <div className={flashContentsClassName}>
            <span className="icon-container">{icon}</span>
            <Body>{message}</Body>
            {props.showCloseIcon && (
              <IconButton
                label="Close flash"
                icon={CloseIcon}
                size="sm"
                onClick={props.closeFlash}
              ></IconButton>
            )}
          </div>
        </TimedFade>,
        mountElement
      )}
    </>
  );
};

export type FlashProps = {
  message: string;
};

export const SuccessFlash = (props: FlashProps) => {
  const { message } = props;

  return (
    <Flash
      icon={<CheckIcon color="green" />}
      backgroundColor="extra-light-green"
      message={message}
    />
  );
};

export const ErrorFlash = (props: FlashProps) => {
  const { message } = props;

  return (
    <Flash icon={<ErrorIcon color="red" />} backgroundColor="extra-light-red" message={message} />
  );
};

export const LoadingFlash = (props: FlashProps) => {
  const { message } = props;

  return (
    <Flash
      icon={<LoadingSpinner size="small" color="green" />}
      backgroundColor="white"
      message={message}
    />
  );
};
