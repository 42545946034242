import { useEffect, useMemo, useState } from "react";
import { useBoardConfiguration } from "~/hooks/use_board_configuration";
import { type Badges } from "~/utils/data_warehouse/client";
import { fetchBadges } from "./fetch_badges";
import { Content } from "./content";
import "./badge_panel.scss";

interface Props {
  hiringPlanId?: number | string | null;
}

export const BadgePanel = ({ hiringPlanId }: Props) => {
  const { display_job_trust_profiles } = useBoardConfiguration();

  const show = useMemo(
    () => (display_job_trust_profiles && !!hiringPlanId) || false,
    [hiringPlanId, display_job_trust_profiles]
  );

  const [badges, setBadges] = useState<string[]>([]);

  useEffect(() => {
    if (!show) {
      return;
    }

    async function loadBadges() {
      const { badges: fetchedBadges, success } = await fetchBadges(hiringPlanId!);

      if (!success || fetchedBadges === null) {
        setBadges([]);
      } else {
        setBadges(Object.keys(fetchedBadges).filter((key) => fetchedBadges[key as keyof Badges]));
      }
    }

    loadBadges();
  }, [hiringPlanId, show]);

  if (!show || badges?.length === 0) {
    return null;
  }

  return (
    <div className="badge_panel">
      <Content badgeKeys={badges} />
    </div>
  );
};
