import classNames from "classnames";
import { Body as PrimaryBody, SecondaryBody, TableHeader } from "~/components/typography";
import "./index.scss";
import { Tag, type TagProps } from "../tag";

type Header = string;

export type Cell = {
  primaryBody: string;
  emphasis?: boolean;
  secondaryBody: string;
  href?: string;
  tags?: TagProps[];
};

type Row = Cell[];

interface TableProps {
  headers?: Array<Header> | null;
  rows: Array<Row>;
  rowClassName?: string;
  defaultHeader?: string | null;
}

const Table = (props: TableProps) => {
  const { headers, rows, rowClassName, defaultHeader } = props;

  const className = classNames({
    cell: true,
  });

  const headersExist = headers && headers.length > 0;
  const renderHeaders = headersExist ? (
    headers?.map((header, index) => (
      <th key={"header-" + index} scope="col">
        <TableHeader>{header}</TableHeader>
      </th>
    ))
  ) : (
    <th scope="col" className="visually-hidden">
      <TableHeader>{defaultHeader}</TableHeader>
    </th>
  );

  const renderRows = rows.map((row, index) => (
    <tr className={rowClassName} key={"row-" + index}>
      {row.map((cell, columnIndex) => (
        <td key={"cell-" + columnIndex} className={className}>
          <a href={cell.href} target="_top">
            <PrimaryBody medium={cell.emphasis}>
              {cell.primaryBody}
              {cell.tags?.map((tag, index) => (
                <Tag key={index} label={tag.label} spacing={true} />
              ))}
            </PrimaryBody>
            <SecondaryBody metadata>{cell.secondaryBody}</SecondaryBody>
          </a>
        </td>
      ))}
    </tr>
  ));

  return (
    <table>
      {(headersExist || defaultHeader) && (
        <thead>
          <tr>{renderHeaders}</tr>
        </thead>
      )}
      <tbody>{renderRows}</tbody>
    </table>
  );
};

export default Table;
