import { RemixBrowser } from "@remix-run/react";
import { startTransition, useState, useCallback, StrictMode, type ReactNode } from "react";
import { hydrateRoot } from "react-dom/client";
import initI18next from "./i18next.client";
import { I18nextProvider } from "react-i18next";
import { isInternalRoute } from "./utils/route_helpers";
import rollbar from "./utils/rollbar.client";
import { recoverableErrorHandler } from "./utils/recoverable_error_handler";
import { Resizer } from "./utils/resizer";
import { CacheProvider } from "@emotion/react";
import createEmotionCache from "~/utils/create_emotion_cache";
import ClientStyleContext from "~/contexts/client_style_context";

async function hydrate() {
  const isInternal = isInternalRoute(window.location.pathname);

  const i18next = await initI18next();

  const root = isInternal ? document.getElementById("job-board-renderer") || document : document;

  window.rollbar = rollbar;

  function ClientEmotionCacheProvider({ children }: { children: ReactNode }) {
    const [cache, setCache] = useState(createEmotionCache());

    const reset = useCallback(() => {
      setCache(createEmotionCache());
    }, []);

    return (
      <ClientStyleContext.Provider value={{ reset }}>
        <CacheProvider value={cache}>{children}</CacheProvider>
      </ClientStyleContext.Provider>
    );
  }

  startTransition(() => {
    hydrateRoot(
      root,
      <ClientEmotionCacheProvider>
        <I18nextProvider i18n={i18next}>
          <StrictMode>
            <RemixBrowser />
          </StrictMode>
        </I18nextProvider>
      </ClientEmotionCacheProvider>,
      {
        onRecoverableError: recoverableErrorHandler,
      }
    );
  });
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}

// We have content that was rendered server-side. Resizing the iframe (if this is an embedded page)
// shouldn't be blocked by hydration, otherwise there will be a brief moment where there is content
// but the iframe isn't the correct size.
Resizer.getInstance().handleResize();
