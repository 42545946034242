import type { EeocSection } from "~/types/jben/job_post";
import EeocQuestion from "~/components/job_posts/eeoc/question";
import EeocRaceQuestion from "~/components/job_posts/eeoc/race_question";
import type { FieldValue } from "../field";
import type { EeocFormAnswers } from "./form";

interface Props {
  answers: EeocFormAnswers;
  section: EeocSection;
  errors: Record<string, string>;
  onChange: (key: string, value: FieldValue) => void;
}

const EeocFormSection = ({ answers, section, errors, onChange }: Props) => {
  const isDisabilityStatus =
    section.questions.length > 0 && section.questions[0].fields[0].name == "disability_status";

  const description = section.description?.includes("<h3><strong>")
    ? section.description
        .replace(/<h3><strong>/, `<h2 class='section-header font-primary'>`)
        .replace(/<\/strong><\/h3>/, "</h2>")
    : section.description;

  return (
    <>
      {isDisabilityStatus && <div className="divider" role="separator"></div>}

      {description && <div dangerouslySetInnerHTML={{ __html: description }} />}

      {section.questions.map((question) => {
        const name = question.fields[0].name;
        const isRace = name === "race";

        return (
          <div key={question.label} className="eeoc__question__wrapper">
            {isRace && (
              <EeocRaceQuestion
                question={question}
                error={errors[name]}
                onChange={onChange}
                selected={answers.race}
              />
            )}
            {!isRace && (
              <EeocQuestion
                question={question}
                error={errors[name]}
                onChange={onChange}
                selected={answers[name]}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

export default EeocFormSection;
