import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import i18n from "./i18n.config";
import { getInitialNamespaces } from "remix-i18next";
// @ts-ignore - file is dynamically generated
import mappings from "../locales/mappings.json";

const initI18nextClosure = () => {
  let initialized = false;

  return async () => {
    if (initialized) {
      return i18next;
    }

    await (async function () {
      await i18next
        .use(initReactI18next) // Tell i18next to use the react-i18next plugin
        .use(LanguageDetector) // Setup a client-side language detector
        .use(Backend) // Setup your backend
        .init({
          ...i18n, // spread the configuration
          // This function detects the namespaces your routes rendered while SSR use
          ns: getInitialNamespaces(),
          backend: {
            loadPath: async (lngs: string[], nss: string[]) => {
              const lng = lngs[0];
              const ns = nss[0];

              const file = `locales/${lng}/${ns}.json` as keyof typeof mappings;
              const hash = `${mappings[file]}`;

              return `${window.ENV.ASSET_URL}/locales/{{lng}}/{{ns}}.${hash}.json`;
            },
          },
          detection: {
            // Here only enable htmlTag detection, we'll detect the language only
            // server-side with remix-i18next, by using the `<html lang>` attribute
            // we can communicate to the client the language detected server-side
            order: ["htmlTag"],
            // Because we only use htmlTag, there's no reason to cache the language
            // on the browser, so we disable it
            caches: [],
          },
        });

      initialized = true;
    })();

    return i18next;
  };
};

const initI18next = initI18nextClosure();

export default initI18next;
