import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./job_alert.scss";
import { Body, Link } from "./typography";
import ReminderIcon from "./icons/reminder";
import { useBoardConfiguration } from "~/hooks/use_board_configuration";
import classNames from "classnames";

interface Props {
  urlToken?: string;
  companyName?: string;
  onJobPost?: boolean;
}

export const JobAlert = ({ urlToken = "", companyName = "", onJobPost = false }: Props) => {
  const { t } = useTranslation("job_post");
  const [jobSeekersUrl, setJobSeekersUrl] = useState("");
  const { preview_mode } = useBoardConfiguration();

  useEffect(() => {
    if (!preview_mode) {
      setJobSeekersUrl(`${window.ENV.JOB_SEEKERS_URL}/users/sign_in?job_board=${urlToken}`);
    }
  }, [preview_mode, urlToken]);

  const classes = classNames({
    "job-alert": true,
    "job-alert--margin": onJobPost,
  });

  const descriptionKey = onJobPost ? "job_alert.post_description" : "job_alert.board_description";

  return (
    <div className={classes}>
      <ReminderIcon />
      <div>
        <Body medium>{t("job_alert.title")}</Body>
        <Body>{t(descriptionKey, { companyName, interpolation: { escapeValue: false } })}</Body>
        <Link target="_blank" href={preview_mode ? undefined : jobSeekersUrl} rel="noreferrer">
          {t("job_alert.create")}
        </Link>
      </div>
    </div>
  );
};
