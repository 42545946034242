import { buildSelfDescribingEvent, newTracker, type Tracker } from "@snowplow/node-tracker";
import rollbar from "../rollbar.client";

interface Event {
  name: string;
  data: any;
  schema?: string;
}

const GENERIC_SCHEMA = "iglu:io.greenhouse/generic-event/jsonschema/1-0-0";

export class SnowplowTracker {
  private tracker: Tracker | null = null;

  track(event: Event) {
    try {
      if (!this.enabled) {
        return;
      }

      this.lazyInit();

      this.tracker!.track(
        buildSelfDescribingEvent({
          event: {
            schema: event.schema || GENERIC_SCHEMA,
            data: {
              ...event.data,
              event_name: event.name,
            },
          },
        })
      );
    } catch (e: any) {
      // We should not blow up if we fail to track an event, but we should log to rollbar
      rollbar.error(e);
    }
  }

  // Lazily initialize the tracker to avoid trying to access window.ENV during SSR
  private lazyInit() {
    if (this.initialized) {
      return;
    }

    this.tracker = newTracker(
      {
        appId: window.ENV.SNOWPLOW_APP_ID,
        encodeBase64: false,
        namespace: window.ENV.SNOWPLOW_NAMESPACE,
      },
      {
        endpoint: window.ENV.SNOWPLOW_ENDPOINT,
        eventMethod: "post",
        bufferSize: 1,
      }
    );

    this.tracker.setPlatform("web");
  }

  private get enabled(): boolean {
    return window.ENV.SNOWPLOW_ENABLED || false;
  }

  private get initialized(): boolean {
    return !!this.tracker;
  }
}
