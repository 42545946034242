import { withNamespace } from "~/utils/translation";

type Args = {
  trigger: HTMLAnchorElement | null;
  uploadToS3: (arg: File) => void;
  setUploadError: (arg: string) => void;
  setUploading: (arg: boolean) => void;
};

interface File extends Blob {
  name?: string;
}

export default class GoogleDrivePicker {
  trigger: HTMLAnchorElement | null;
  uploadToS3: (arg: File) => void;
  setUploadError: (arg: string) => void;
  setUploading: (arg: boolean) => void;
  options: Args;
  oauthToken: string;
  pickerShown: boolean;

  constructor(args: Args) {
    this.trigger = args.trigger;
    this.uploadToS3 = args.uploadToS3;
    this.setUploadError = args.setUploadError;
    this.setUploading = args.setUploading;
    this.options = args;
    this.oauthToken = "";
    this.pickerShown = false;

    this.init();

    window.gapiLoaded = this.gapiLoaded;
    window.gisLoaded = this.gisLoaded;
  }

  init() {
    // If the browser doesn't support XHR2, let's remove the trigger.
    if (!window.XMLHttpRequest) {
      this.options.trigger?.remove();
    }
  }

  onClick() {
    const t = withNamespace("job_post", { prefix: "file_upload" });

    if (!window.gapi || !window.gapiInitialized || !window.gisInitialized) {
      console.error("Unable to load Google Drive");
      this.options.setUploadError(t("try_again", { uploader: "Google Drive" }));
      return;
    }

    window.tokenClient.callback = this.handleAuthResult.bind(this);

    if (this.oauthToken === null) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      window.tokenClient.requestAccessToken({ prompt: "consent" });
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      window.tokenClient.requestAccessToken({ prompt: "" });
    }
  }

  handleAuthResult(authResult: any) {
    if (authResult && !authResult.error) {
      this.oauthToken = authResult.access_token;
      this.createPicker();
    }
  }

  createPicker() {
    if (!this.pickerShown && this.oauthToken) {
      let picker = new window.google.picker.PickerBuilder()
        .addView(window.google.picker.ViewId.DOCUMENTS)
        .addView(window.google.picker.ViewId.PDFS)
        .setOAuthToken(this.oauthToken)
        .setDeveloperKey(window.ENV.GOOGLE_PICKER_DEVELOPER_KEY)
        .setAppId(window.ENV.GOOGLE_PICKER_APP_ID)
        .setCallback(this.pickerCallback.bind(this))
        .enableFeature(window.google.picker.Feature.SUPPORT_DRIVES)
        .build();

      picker.setVisible(true);
      this.pickerShown = true;
    }
  }

  pickerCallback(data: any) {
    switch (data[window.google.picker.Response.ACTION]) {
      case window.google.picker.Action.PICKED:
        this.pickedFile(data);
        this.pickerShown = false;
        break;
      case window.google.picker.Action.CANCEL:
        this.pickerShown = false;
        break;
    }
  }

  pickedFile(data: any) {
    let doc = data[window.google.picker.Response.DOCUMENTS][0];

    this.downloadFile({
      id: doc[window.google.picker.Document.ID],
      mimeType: doc[window.google.picker.Document.MIME_TYPE],
      name: doc[window.google.picker.Document.NAME],
      type: doc[window.google.picker.Document.TYPE],
    });
  }

  async downloadFile(metadata: { id: string; mimeType: string; name: string; type: string }) {
    let response;
    let mimeType = metadata.mimeType;
    let filename = metadata.name;

    this.setUploading(true);

    if (metadata.type === "document") {
      response = await window.gapi.client.drive.files.export({
        fileId: metadata.id,
        mimeType: "application/pdf",
      });

      mimeType = "application/pdf";
      filename = filename + ".pdf";
    } else {
      response = await window.gapi.client.drive.files.get({
        fileId: metadata.id,
        supportsAllDrives: true,
        alt: "media",
      });
    }

    let arrayBuffer = this.dataToArrayBuffer(response.body);
    let file = new Blob([arrayBuffer], { type: mimeType }) as File;

    file.name = filename;

    this.options.uploadToS3(file);
  }

  dataToArrayBuffer(data: any) {
    if (!data) {
      return new Uint8Array(0);
    }

    let arrayBuffer = new Uint8Array(data.length);

    for (let i = 0; i < data.length; i++) {
      arrayBuffer[i] = data.charCodeAt(i);
    }

    return arrayBuffer;
  }

  initializeGapiClient = async () => {
    await window.gapi.client.load("https://www.googleapis.com/discovery/v1/apis/drive/v3/rest");
    window.gapiInitialized = true;
  };

  gapiLoaded = () => {
    window.gapi.load("client:picker", this.initializeGapiClient);
  };

  gisLoaded = () => {
    window.tokenClient = window.google.accounts.oauth2.initTokenClient({
      client_id: window.ENV.GOOGLE_PICKER_CLIENT_ID,
      scope: "https://www.googleapis.com/auth/drive.file",
      callback: () => {},
    });

    window.gisInitialized = true;
  };
}
