import "./demographic_section.scss";
import { BodyDiv, SectionHeader } from "../typography";
import { type DemographicQuestion } from "~/types/jben/job_post";
import { type DemographicAnswer } from "~/utils/build_application";
import { useMemo } from "react";
import {
  DemographicField,
  type DemographicFieldOptions,
  type DemographicFieldProps,
} from "./demographic_field";

interface Props {
  title: string;
  description: string;
  questions: DemographicQuestion[];
  errors: Record<string, string>;
  onChange?: (key: string, value: DemographicAnswer[]) => void;
}

export const DemographicSection = ({ title, description, questions, errors, onChange }: Props) => {
  const handleChange = (key: string, value: DemographicAnswer[]) => {
    if (!onChange) {
      return;
    }

    onChange(key, value);
  };

  const fields: Omit<DemographicFieldProps, "onChange">[] = useMemo(
    () =>
      questions.map((question) => {
        const type =
          question.answer_type?.key === "MULTI_SELECT"
            ? "multi_value_multi_select"
            : "multi_value_single_select";

        const options: DemographicFieldOptions = question.answer_options.map((answerOption) => ({
          label: answerOption.name,
          value: answerOption.id.toString(),
          freeform: answerOption.free_form,
        }));

        return {
          label: question.name,
          name: question.id.toString(),
          error: errors[question.id.toString()],
          required: question.required,
          options,
          type,
        };
      }),
    [questions, errors]
  );

  return (
    <div id="demographic-section" className="demographic--container">
      <SectionHeader>{title}</SectionHeader>
      <BodyDiv>
        <div dangerouslySetInnerHTML={{ __html: description }}></div>
      </BodyDiv>
      {fields.map((field) => (
        <DemographicField
          key={field.name}
          {...field}
          onChange={(value) => handleChange(field.name, value)}
        />
      ))}
    </div>
  );
};
