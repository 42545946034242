import { type EmploymentAnswer } from "~/utils/build_application";
import { type QuickApplyProfile } from "../types";
import { type EmploymentConfiguration } from "~/types/jben/job_post";

export class EmploymentAutofiller {
  private answers: EmploymentAnswer[];
  private config: EmploymentConfiguration;
  private setAnswers: (answers: EmploymentAnswer[]) => void;

  constructor(
    answers: EmploymentAnswer[],
    setAnswers: (answers: EmploymentAnswer[]) => void,
    config: EmploymentConfiguration
  ) {
    this.answers = answers;
    this.setAnswers = setAnswers;
    this.config = config;
  }

  async fill(profile: QuickApplyProfile): Promise<void> {
    if (!this.shouldFill) {
      return;
    }

    const newAnswers = this.profileToAnswers(profile);

    if (newAnswers.length === 0) {
      return;
    }

    this.setAnswers(newAnswers);
  }

  private profileToAnswers(profile: QuickApplyProfile): EmploymentAnswer[] {
    if (!profile.employments) {
      return [];
    }

    return profile.employments.map((employment, index) => {
      return {
        key: this.answers.length + index,
        companyName: employment.company,
        title: employment.title,
        startDateMonth: employment.startDate.month,
        startDateYear: employment.startDate.year,
        endDateMonth: employment.endDate?.month,
        endDateYear: employment.endDate?.year,
        currentRole: !employment.endDate,
      } as EmploymentAnswer;
    });
  }

  private get shouldFill(): boolean {
    if (!this.hasEmploymentQuestions) {
      return false;
    }

    // If the user has manually provided ANY employment answer, then we should not autofill
    return !this.answers.some((answer) => {
      // The key property is only used to identify the answers, so we should ignore
      // it when determining if the user has manually provided an answer.
      const { key: _key, ...attributes } = answer;

      // If any key has a value, then the user has manually provided an answer.
      return Object.keys(attributes).some((key) => !!attributes[key as keyof typeof attributes]);
    });
  }

  private get hasEmploymentQuestions(): boolean {
    return this.config === "optional" || this.config === "required";
  }
}
