import type { Question } from "~/types/jben/job_post";
import S3Uploader from "~/utils/uploads/s3_uploader";

export type Field = "resume" | "cover_letter" | string;
export type Fields = Field[];
export type FormFields = {
  "x-amz-server-side-encryption": string;
  success_action_status: string;
  policy: string;
  "x-amz-credential": string;
  "x-amz-algorithm": string;
  "x-amz-date": string;
  "x-amz-security-token": string;
  "x-amz-signature": string;
};

type JsonResponse = {
  [key: Field]: {
    fields: FormFields;
    key: string;
  };
} & { url: string };

type Uploaders = Record<Field, S3Uploader>;

export default class UploadManager {
  uploaders: Uploaders;

  constructor() {
    this.uploaders = {} as Uploaders;
  }

  inputFilesFrom(questions: Question[]) {
    if (!questions) return [];

    return questions.reduce((memo: Fields, question) => {
      question.fields.forEach((field) => {
        if (field.type === "input_file") {
          memo.push(field.name);
        }
      });

      return memo;
    }, []);
  }

  async fetchFields(fields: Fields) {
    const { JBEN_URL } = window.ENV;

    const queryParams = new URLSearchParams();
    fields.forEach((field) => queryParams.append("fields[]", field));

    let json: JsonResponse;
    try {
      const response = await fetch(
        `${JBEN_URL}/uncacheable_attributes/presigned_fields?${queryParams}`
      );

      json = await response.json();
    } catch (e) {
      console.error(`Failed to fetch uncacheable_attributes - ${e}`);
      throw e;
    }

    fields.reduce((memo, field) => {
      const data = json[field];

      memo[field] = new S3Uploader({
        field,
        formFields: data.fields,
        key: data.key,
        url: json.url,
      });

      return memo;
    }, this.uploaders);
  }
}
