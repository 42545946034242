const DEFAULT_OFFSET = 120;

const getScrollTop = (element: HTMLElement, offset: number | null = null) => {
  const top = element.getBoundingClientRect().top - document.body.getBoundingClientRect().top;

  return top - (offset || DEFAULT_OFFSET);
};

export const scrollToElement = (element: HTMLElement | null) => {
  if (!element) {
    return;
  }

  window.scrollTo({
    behavior: "smooth",
    top: getScrollTop(element),
  });
};
